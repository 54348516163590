const { default: BaseMindElementDataCalculation } = require("./BaseMindElementDataCalculation");
const { default: Size } = require("../../../../viewmodel/core/base/Size");

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndRemarksCalculation extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainRemarksContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let remarksElementContent = this.data.remarksElementContent;
        if (textMindElementContent == null) {
            return;
        }
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        remarksElementContent.width = textHeight;
        remarksElementContent.height = textHeight;
        let size = this.includeRemarksAndTextSize();
        let iconsSize = new Size(remarksElementContent.width, remarksElementContent.height);

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        if (!this.data.isContainRemarksContent()) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

        remarksElementContent.x = iconLeft + marginX;
        remarksElementContent.y = iconTop + marginY;

        // this.setMindElementSize(size.getWidth(), size.getHeight(), marginX, marginY);
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}
export default TextAndRemarksCalculation