import Colors from "../../../utils/Colors";
import UiUtil from "../../../utils/UiUtil";
import CGRect from "../base/basedata/Rect";

class BaseShape {
    constructor(data, space = 0) {
        this.data = data;
        this.space = space;
        this.x = data.x;
        this.y = data.y;
        this.width = data.width;
        this.height = data.height
    }

    isBorderColor() {
        if (this.data == null || this.data.isEmpty()) {
            return false
        }
        return this.data.borderWidth > 0 && !Colors.isClear(this.data.borderColor);
    }

    borderWidth() {
        if (!this.isBorderColor()) {
            return 0
        }
        return this.data.borderWidth;
    }

    rect(margin = 0) {
        if (this.data == null || this.data.isEmpty()) {
            return new CGRect(0, 0, 0, 0);
        }
        let borderWidth = this.borderWidth();
        return new CGRect(this.data.x - borderWidth / 2 - margin - this.space, this.data.y - borderWidth / 2 - margin - this.space, 
                                this.data.width + borderWidth + margin * 2 + this.space * 2, this.data.height + borderWidth + margin * 2 + this.space * 2);
    }

    isLeft(point) {
        if (this.data == null || this.data.isEmpty()) {
            return false
        }
        return point.x <= this.data.x;
    }

    isRight(point) {
        if (this.data == null || this.data.isEmpty()) {
            return false
        }
        return point.x >= this.data.x + this.data.width;
    }

    isTop(point) {
        if (this.data == null || this.data.isEmpty()) {
            return false
        }
        return point.y <= this.data.y;
    }

    isBottom(point) {
        if (this.data == null || this.data.isEmpty()) {
            return false
        }
        return point.y >= this.data.y + this.data.height;
    }
}
export default BaseShape