const KeyboardValueType = {
    ADD_CHILD_NODE:1, //添加子节点
    ADD_TOP_CHILD_NODE:2,//添加上子节点
    ADD_BOTTOM_CHILD_NODE:3,//添加下子节点
    REVOKE:4,//撤销
    RECOVERY:5,//恢复
    EXIT_EDIT_STATUS:6,//退出编辑
    MODIFY_CURRENT_NODE:7,//修改当前节点
    DELETE:8,//删除当前节点
    ENLARGE_MAP:9,//放大导图
    SHRINK_MAP:10,//缩小导图
    SELECT_MASTER:11,//选择主节点
    COPY:12,//复制节点
    PASTE:13,//粘贴节点
    BACKSPACE:14,//删除当前节点(非编辑状态)

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.ADD_CHILD_NODE;
    }

}

export default KeyboardValueType