
class HashMap {
    constructor() {
        this.key_prefix = "mindmap_key_";
        this.mapObj = new Object();
    }

    //获取MAP元素个数
    size() {
        var size = 0;
        for(let mealName of Object.keys(this.mapObj)){
            if(mealName.indexOf(this.key_prefix) > -1){
                size++;
            }
        }
        return size;
    }

    //判断MAP是否为空
    isEmpty() {
        for(let mealName of Object.keys(this.mapObj)) {
            if(mealName.indexOf(this.key_prefix) > -1){
                return false
            }
        }
        return true;
    }

    //删除MAP所有元素
    clear() {
        this.mapObj = new Object();
    }

    //向MAP中增加元素（key, value) 
    put(_key, _value) {
        this.mapObj[this.key_prefix + _key] = _value;
    }

    //删除指定KEY的元素，成功返回True，失败返回False
    remove(_key) {
        this.mapObj[this.key_prefix + _key] = null;
        delete this.mapObj[this.key_prefix + _key];
    }

    //获取指定KEY的元素值VALUE，失败返回NULL
    get(_key) {
        return this.mapObj[this.key_prefix + _key]
    }

    //判断MAP中是否含有指定KEY的元素
    containsKey(_key) {
        return this.mapObj[this.key_prefix + _key] != null && this.mapObj[this.key_prefix + _key] != undefined 
    }

    //判断MAP中是否含有指定VALUE的元素
    containsValue(_value) {
        for(let mealName of Object.keys(this.mapObj)){
            if(mealName.indexOf(this.key_prefix) > -1 && this.mapObj[mealName] == _value){
                return true
            }
        }
        return false;
    }

    //获取MAP中所有VALUE的数组（ARRAY）
    values() {
        var arr = new Array();
        for(let mealName of Object.keys(this.mapObj)){
            if(mealName.indexOf(this.key_prefix) > -1){
                arr.push(this.mapObj[mealName])
            }
        }
        return arr;
    }

    //获取MAP中所有KEY的数组（ARRAY）
    keys() {
        var arr = new Array();
        for(let mealName of Object.keys(this.mapObj)){
            if(mealName.indexOf(this.key_prefix) > -1) {
                arr.push(mealName.replace(this.key_prefix, ""))
            }
        }
        return arr;
    }
}

export default HashMap