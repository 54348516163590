let MindElementAlignmentType = {
    UNKNOWN:-1,
    LEFT:0,
    MIDDLE:1,
    RIGHT:2,

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.MIDDLE;
    }
}

export default MindElementAlignmentType