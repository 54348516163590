
//rgb转16 10 进制

class colorToSystemOrNodeColor {
    ColorToSystem(Color) {
        if(Color == 1){
            return 1
        }
        if(Color == null){
            return null
        }
        if ((typeof Color) == "number") {
                return Color
        } else if ((typeof Color) != "string") {
                return Color;
        }
        Color = Color.toLowerCase()
        if (Color.indexOf("rgba") > -1) {
            let colorStyle = Color.substring(
                Color.indexOf("r"),
                Color.lastIndexOf("a") + 1
            );
            if (colorStyle == "rgba") {
                let colorStryleAccout = Color.substring(
                    Color.indexOf("rgba(") + 5,
                    Color.indexOf(")")
                );
                let list = colorStryleAccout.split(",")
                let r = "";
                let g = "";
                let b = "";
                if (list.length >= 3) {
                    r = list[0]
                    g = list[1]
                    b = list[2]
                } else {
                    r = colorStryleAccout.substring(
                        colorStryleAccout.indexOf(""),
                        colorStryleAccout.indexOf(",")
                    );
                    g = colorStryleAccout.substring(
                        colorStryleAccout.indexOf(",") + 1,
                        colorStryleAccout.lastIndexOf(",")
                    );
                    b = colorStryleAccout.substring(
                        colorStryleAccout.lastIndexOf(",") + 1,
                        colorStryleAccout.lastIndexOf("")
                    );
                }
                
                let colors = (r, g, b) => {
                    return ((r << 16) | (g << 8) | b).toString(16);
                };
                return "0x" + colors(r, g, b)
            } else {
                let colorAccout = Color.substring(Color.indexOf("#") + 1);
                return "0x" + colorAccout
            }
        } else if (Color.indexOf("rgb") > -1) {
            let colorStyle = Color.substring(
                Color.indexOf("r"),
                Color.lastIndexOf("b") + 1
            );
            if (colorStyle == "rgb") {
                let colorStryleAccout = Color.substring(
                    Color.indexOf("rgb(") + 4,
                    Color.indexOf(")")
                );
                let r = colorStryleAccout.substring(
                    colorStryleAccout.indexOf(""),
                    colorStryleAccout.indexOf(",")
                );
                let g = colorStryleAccout.substring(
                    colorStryleAccout.indexOf(",") + 1,
                    colorStryleAccout.lastIndexOf(",")
                );
                let b = colorStryleAccout.substring(
                    colorStryleAccout.lastIndexOf(",") + 1,
                    colorStryleAccout.lastIndexOf("")
                );
                let colors = (r, g, b) => {
                    return ((r << 16) | (g << 8) | b).toString(16);
                };
                return "0x" + colors(r, g, b)
            } else {
                let colorAccout = Color.substring(Color.indexOf("#") + 1);
                return "0x" + colorAccout
            }
        } else if (Color.indexOf("#") > -1) {
            let colorAccout = Color.substring(Color.indexOf("#") + 1);
            return "0x" + colorAccout
        } else {
            return Color
        }
    }

    SystemToColor(Color) {
        if(Color == null){
            return
        }
        if (typeof Color != "number") {
            "#" + Color.substring(Color.indexOf("0x") + 2);
            if (Color == "#0") {
                return "#000000";
            }
        } else {
            return "#" + Color.toString(16);
        }
    }

    nodeUpdataBeforeColor(color) {
        if(color == null){
            return ""
        }
        //是否透明
        if(color == 1){
            return 1
        }
        let colorAccout = color.substring(color.indexOf("0x") + 2);
        if (colorAccout == 0) {
            return "#000000";
        } else {
            return "#" + colorAccout;
        }
    }
    
}
const colorChangeClass = new colorToSystemOrNodeColor()
export default colorChangeClass