import OrderType from "../../datatype/OrderType";
import MindElementContent from "./MindElementContent";

class OrderContent extends MindElementContent {
    constructor(w, h) {
        super(w, h);
        this.parentNodeOrderLevel = -1;
        this.orderType = OrderType.Order_1;
        this.orderDepth = 1;
        this.order = "";
    }
    
    copy() {
        let data = new OrderContent(this.width, this.height);
        data.x = this.x;
        data.y = this.y;
        data.parentNodeOrderLevel = this.parentNodeOrderLevel;
        data.orderType = this.orderType;
        data.orderDepth = this.orderDepth;
        data.order = this.order;
        return data;
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
            result = result || data.order != this.order;
        }
        result = result || data.parentNodeOrderLevel != this.parentNodeOrderLevel;
        result = result || data.orderType != this.orderType;
        result = result || data.orderDepth != this.orderDepth;

        return !result;
    }
}

export default OrderContent