// package mind.yushu.com.mindmap.core.calcule.elementCalculation.widget;

import UiUtil from "../../../../../utils/UiUtil";
import Util from "../../../../../utils/Util";
import CGPoint from "../../../../../viewmodel/core/base/basedata/CGPoint";
import CGRect from "../../../../../viewmodel/core/base/basedata/Rect";
import MindPoint from "../../../../../viewmodel/mindelementdata/mindcontent/MindPoint";
import BaseMindElementDataCalculation from "../BaseMindElementDataCalculation";
import MindElementCalculation from "../MindElementCalculation";

// import android.util.Size;

// import java.util.ArrayList;
// import java.util.List;

// import mind.yushu.com.mindmap.core.calcule.elementCalculation.BaseMindElementDataCalculation;
// import mind.yushu.com.mindmap.core.calcule.elementCalculation.MindElementCalculation;
// import mind.yushu.com.mindmap.utils.UiUtil;
// import mind.yushu.com.mindmap.utils.Util;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGPoint;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGRect;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.MindPoint;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.StatisticsCellData;

// /**
//  * ProjectName: MindMap
//  * Created by tony on 1/12/21
//  * Copyright(c) 2020 mindyushu.com
//  */

class CakeShapeCalculation extends BaseMindElementDataCalculation {
    constructor(data) {
        super(data)
        this.contextSpace = new UiUtil().dip2px(10); 
        this.explainLineContextSpace = new UiUtil().dip2px(10);
        this.explainTextSpace =  new UiUtil().dip2px(20);
        this.explainTextSpaceV = new UiUtil().dip2px(8);
        this.titleV = new UiUtil().dip2px(0);
        this.explainLine = new UiUtil().dip2px(80);
    }

    // public CakeShapeCalculation(MindElementData data) {
    //     super(data);
    // }

    calculate() {
        if (!this.data.isStatisticsContent()) {
            return;
        }
        let radius = this.data.statisticsContent.radius;
        this.explainLine = radius / 2 + this.explainLineContextSpace;
        let center = new CGPoint(radius, radius);
        let titleWidth = 0;
        let titleHeight = 0;
        let titleX = 0;
        let titleY = 0;
        let count = this.data.statisticsContent.cellsData.length;
        let totalValue = this.getTotalValue();
        let startAngle = 270.0;
        let rect = new CGRect(0, 0, radius * 2, radius * 2);
        let right = radius * 2;
        let bottom = radius * 2;
        let statisticsCellBottom = radius * 2;
        for (let index = 0; index < count; index++) {
            let cell = this.data.statisticsContent.cellsData[index];
            cell.x = center.x;
            cell.y = center.y;
            cell.width = radius * radius;
            cell.height = cell.width;
            cell.startAngle = startAngle;
            cell.angle = 360 * (cell.value/totalValue);

            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);
            let explainLines = new Array();
            let explainLineAngle = (cell.startAngle + cell.angle/2) % 360;
            let point0X = center.x + ((radius + this.explainLineContextSpace) * Util.cosd(explainLineAngle));
            let point0Y = center.y + ((radius + this.explainLineContextSpace) *  Util.sind(explainLineAngle));
            statisticsCellBottom = Math.max(statisticsCellBottom, point0Y);
            explainLines.push(new MindPoint(point0X, point0Y));
            let point1X = center.x + ((radius + this.explainLine) * Util.cosd(explainLineAngle));
            let point1Y = center.y + ((radius + this.explainLine) *  Util.sind(explainLineAngle));
            rect.x = Math.min(rect.x, point1X);
            rect.y = Math.min(rect.y, point1Y);
            right = Math.max(right , point1X);
            bottom = Math.max(bottom , point1Y);
            explainLines.push(new MindPoint(point1X, point1Y));
            let point2X = 0;
            let point2Y = point1Y;
            if (explainTitleSize.getWidth() > explainSize.getWidth()) {
                if (explainLineAngle > 270 || explainLineAngle < 90) {
                    point2X = point1X + explainTitleSize.getWidth() + this.explainTextSpace;
                    cell.explainTitleX = point2X - explainTitleSize.getWidth();
                    cell.explainX = cell.explainTitleX + (explainTitleSize.getWidth() - explainSize.getWidth()) / 2;
                } else {
                    point2X = point1X - explainTitleSize.getWidth() - this.explainTextSpace;
                    cell.explainTitleX = point2X;
                    cell.explainX = cell.explainTitleX + (explainTitleSize.getWidth() - explainSize.getWidth()) / 2;
                }
            } else {
                if (explainLineAngle > 270 || explainLineAngle < 90) {
                    point2X = point1X + explainSize.getWidth() + this.explainTextSpace;
                    cell.explainX = point2X - explainSize.getWidth();
                    cell.explainTitleX = cell.explainX + (explainSize.getWidth() - explainTitleSize.getWidth()) / 2;
                } else {
                    point2X = point1X - explainSize.getWidth() - this.explainTextSpace;
                    cell.explainX = point2X;
                    cell.explainTitleX = cell.explainX + (explainSize.getWidth() - explainTitleSize.getWidth()) / 2;
                }
            }
            cell.explainTitleY = point1Y - explainTitleSize.getHeight() - this.explainTextSpaceV;
            cell.explainY = point1Y + this.explainTextSpaceV;
            statisticsCellBottom = Math.max(statisticsCellBottom, cell.explainY + explainSize.getHeight());
            explainLines.push(new MindPoint(point2X, point2Y));
            cell.explainLines = explainLines;
            rect.x = Math.min(rect.x, point2X);
            rect.y = Math.min(rect.y, cell.explainTitleY);
            right = Math.max(right , point2X);
            bottom = Math.max(bottom , cell.explainY + explainSize.getHeight());

            startAngle = (startAngle + cell.angle) % 360;
        }
//        Size titleSize = MindElementCalculation.caluleText(this.data.statisticsContent.title, this.data.statisticsContent.titleFontSize, this.data.statisticsContent.titleTextBold);
//        titleWidth = titleSize.getWidth();
//        titleHeight = titleSize.getHeight();

        this.data.statisticsContent.titleX = (radius * 2 - titleWidth) / 2;
        this.data.statisticsContent.titleY = statisticsCellBottom + this.titleV;

        rect.x = Math.min(rect.x, this.data.statisticsContent.titleX);
        rect.y = Math.min(rect.y, this.data.statisticsContent.titleY);
        right = Math.max(right , this.data.statisticsContent.titleX);
        bottom = Math.max(bottom , this.data.statisticsContent.titleY + titleHeight);

        let moveX = Math.abs(rect.x) + this.contextSpace;
        let moveY = Math.abs(rect.y) + this.contextSpace;
        for (let index = 0; index < count; index++) {
            let cell = this.data.statisticsContent.cellsData[index];
            cell.x = cell.x + moveX;
            cell.y = cell.y + moveY;
            let linesCount = cell.explainLines.length;
            for (let l = 0; l < linesCount; l++) {
                cell.explainLines[l].x = cell.explainLines[l].x + moveX;
                cell.explainLines[l].y = cell.explainLines[l].y + moveY;
            }
            cell.explainX = cell.explainX + moveX;
            cell.explainY = cell.explainY + moveY;
            cell.explainTitleX = cell.explainTitleX + moveX;
            cell.explainTitleY = cell.explainTitleY + moveY;
        }
        this.data.statisticsContent.titleX = this.data.statisticsContent.titleX + moveX;
        this.data.statisticsContent.titleY = this.data.statisticsContent.titleY + moveY;

        this.data.width = right - rect.x + this.contextSpace * 2;
        this.data.height = bottom - rect.y + this.contextSpace * 2;
    }

    getTotalValue() {
        let value = 0;
        let count = this.data.statisticsContent.cellsData.length;
        for (let index = 0; index < count; index++) {
            let cell = this.data.statisticsContent.cellsData[index];
            value += cell.value;
        }
        return value;
    }
}

export default CakeShapeCalculation
