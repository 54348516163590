import Size from "../../../../viewmodel/core/base/Size";
import TextAndConnectMapCalculation from "./TextAndConnectMapCalculation";

const { default: BaseMindElementDataCalculation } = require("./BaseMindElementDataCalculation");

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */
 
class TextAndConnectMapAndLatexCalculation extends TextAndConnectMapCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainConnectMapContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let connectMapElementContent = this.data.connectMapElementContent;

        let textRext = this.caluleTextCGRect();
        let textHeight = textRext.height();
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;
        let size = this.includeConnectMapAndTextAndLatexSize();
        let connectMapAndTextSize = this.includeConnectMapAndTextSize();
        let iconsSize = new Size((connectMapElementContent.width), (connectMapElementContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        if (!this.data.isContainConnectMapContent()) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (connectMapAndTextSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (connectMapAndTextSize.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((connectMapAndTextSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

        connectMapElementContent.x = iconLeft + marginX;
        connectMapElementContent.y = iconTop + marginY;

        let textEdgeInsets = this.getTextEdgeInsets();
        this.data.latexContent.x = marginX + textEdgeInsets.left;
        this.data.latexContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.latexContent.width = this.latexOriginalSize.getWidth();
        this.data.latexContent.height = this.latexOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}
export default TextAndConnectMapAndLatexCalculation
