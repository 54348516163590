let EncircleTitleLayoutType = {
    TOP_LEFT:1,
    TOP_MIDDLE:2,
    TOP_RIGHT:3,
    MIDDLE_LEFT:4,
    MIDDLE_MIDDLE:5,
    MIDDLE_RIGHT:6,
    TOP_INSIDE_FULL:7,
    TOP_INSIDE_RIGHT:8,
    TOP_INSIDE_LEFT:9,
    BOTTOM_INSIDE_FULL:10,
    BOTTOM_INSIDE_RIGHT:11,
    BOTTOM_INSIDE_LEFT:12,

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.LAYOUT_TRAPEZOID;
    }

}
export default EncircleTitleLayoutType