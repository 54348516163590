

import Size from "../../../../viewmodel/core/base/Size";
import TextAndLinkCalculation from "./TextAndLinkCalculation";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndLinkAndLatexCalculation extends TextAndLinkCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainLinkContent() || !this.data.isContainLatextContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let linkElementContent = this.data.linkElementContent;
        if (textMindElementContent == null) {
            return;
        }
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        linkElementContent.width = textHeight;
        linkElementContent.height = textHeight;

        let size = this.includeLinkAndTextAndLatexSize();
        let linkAndTextSize = this.includeLinkAndTextSize();
        let iconsSize = new Size(linkElementContent.width, linkElementContent.height);
        let textEdgeInsets = this.getTextEdgeInsets();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;

        iconLeft = (linkAndTextSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (linkAndTextSize.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((linkAndTextSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

        linkElementContent.x = iconLeft + marginX;
        linkElementContent.y = iconTop + marginY;

        this.data.latexContent.x = marginX + textEdgeInsets.left;
        this.data.latexContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.latexContent.width = this.latexOriginalSize.getWidth();
        this.data.latexContent.height = this.latexOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndLinkAndLatexCalculation
