
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import TextAndImageAndConnectMap from "./TextAndImageAndConnectMap";


/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndConnectMapAndTask extends TextAndImageAndConnectMap {
    constructor(data, nodeLayoutType) {
        super(data, nodeLayoutType);
    }
    // public TextAndImageAndConnectMap(MindElementData data, NodeLayoutType nodeLayoutType) {
    //     super(data, nodeLayoutType);
    // }

    calculate() {
        if (this.data.imageContent == null || !this.data.isContainConnectMapContent()) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();

        let connectMapElementContent = this.data.connectMapElementContent;
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;

        let textConnectMapSize = this.includeConnectMapAndTextSize();
        let size = this.includeImageAndTextConnectMapAndTaskSize();
        let imageAndTextConnectMapSize = this.includeImageAndTextConnectMapSize();
        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;
        let connectMapTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {
            iconTop = (imageAndTextConnectMapSize.getHeight() - iconsSize.getHeight()) / 2;
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconMargin) : 0;
            iconLeft = iconTop > iconLeft ? iconLeft : iconTop;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((imageAndTextConnectMapSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + connectMapTextSpace;
            connectMapElementContent.y = ((imageAndTextConnectMapSize.getHeight()) - connectMapElementContent.height) / 2;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            iconLeft = (imageAndTextConnectMapSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
            iconTop = (imageAndTextConnectMapSize.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((imageAndTextConnectMapSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + connectMapTextSpace;
            connectMapElementContent.y = ((imageAndTextConnectMapSize.getHeight()) - connectMapElementContent.height) / 2;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (imageAndTextConnectMapSize.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = iconLeft > (this.iconSpace) ? (this.iconSpace) : iconLeft;
            textMindElementContent.x = ((imageAndTextConnectMapSize.getWidth()) - (textConnectMapSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + connectMapTextSpace;
            connectMapElementContent.y = textMindElementContent.y + ((textConnectMapSize.getHeight()) - connectMapElementContent.height) / 2;
        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        let textEdgeInsets = this.getTextEdgeInsets();
        this.data.taskContent.x = marginX + textEdgeInsets.left;
        this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}

export default TextAndImageAndConnectMapAndTask