// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * Created by tony on 2020/5/27
 */

let ConnectLineType = {

    STRAIGHT_ARROW_LINE:1,
    STRAIGHT_CIRCULAR_LINE:2,
    CURVE_LINE:3,
    RIGHT_ANGLE_LINE:4,

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.STRAIGHT_ARROW_LINE;
    }
}

export default ConnectLineType
