// package mind.yushu.com.mindmap.viewmodel.core.base.basedata;

// import android.graphics.Rect;
// import android.util.Size;

import Size from "../../../core/base/Size"

/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/29
 * Copyright(c) 2020 mindyushu.com
 */

class CGRect {
    constructor(x = -1, y = -1, width = 0, height = 0) {
        this.x = x; //java中的left
        this.y = y; //java中的top
        this.size = new Size(width, height);
    }

    init() {
        return new CGRect(-1, -1, 0, 0);
    }

    CGRect(x, y, width, height) {
        this.x = x;
        this.y = y;
        this.size = new Size(width, height);
    }

    width(w) {
        if (arguments.length == 0) {
            return this.size.getWidth();
        } else if (arguments.length == 1) {
            this.size = new Size(w, this.height());
        }
    }

    height(h) {
        if (arguments.length == 0) {
            return this.size.getHeight();
        } else if (arguments.length == 1) {
            this.size = new Size(this.width(), h);
        }
    }

    setSize(size) {
        this.size = size;
    }

    getRect() {
        return new CGRect(this.x, this.y, this.size.getWidth(), this.size.getHeight());
    }
}
export default CGRect
