class MindSectionChangeData {
    constructor() {
        this.time = 0;
        this.id = -1;
    }
    
    copy() {
        let data = new MindSectionChangeData();
        data.time = this.time;
        data.id = this.id;
        return data;
    }

    equal(data) {
        let result = false;
        result = result || data.id != this.id;
        result = result || data.time != this.time;

        return !result;
    }

    merged(data) {
        if (this.id <= -1) {
            this.id = data.id;
            this.time = data.time;
        }
        if (this.id == data.id && data.time > this.time) {
            this.time = data.time;
        }
    }
 }
 export default MindSectionChangeData
