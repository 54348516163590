import Strings from "@/common/lang/strings";
import getString from "@/common/lang/language";
import MindSettingData from "./MindSettingData";
import MindKeyboard from "./MindKeyboard";
import KeyboardValueType from "./KeyboardValueType";

class MindSettingManager {
    constructor() {
        this.mindSettingData = new MindSettingData();
        this.isMacComputed = false;
        this.loadData();
    }
    getMacComputed(val) {
        this.isMacComputed = val;
        this.saveConfig();
    }
    get() {
        return this;
    }
    loadData() {
        let settingData = localStorage.getItem('mindSettingData');
        if (settingData) {
            settingData = new MindSettingData().format(settingData);

            settingData.mindKeyboards.map(item => {
                if (!item.hasOwnProperty('shortcutKey')) {
                    this.setDefaultMindKeyboards();
                }
            })
            
            this.mindSettingData = settingData;
        } else {
            this.setDefaultMindKeyboards();
        }
    }
    saveConfig() {
        let settingData = JSON.stringify(this.mindSettingData);
        if (this.isMacComputed) {
            //mac电脑,Ctrl换成Command，放大导图换成Command+=，mac键盘没有单独的[+]按键
            settingData = settingData.replace(/Ctrl/g, "Command");
            settingData = settingData.replace("Command++", "Command+=");
        }
        localStorage.setItem('mindSettingData', settingData);
        this.loadData();
    }
    setDoubleClickCreateFreeNode(value) {
        this.mindSettingData.doubleClickCreateFreeNode = value;
        this.saveConfig();
    }
    getDoubleClickCreateFreeNode() {
        return this.mindSettingData.doubleClickCreateFreeNode;
    }

    getClickImagePreview() {
        return this.mindSettingData.clickImagePreview;
    }

    setMindExportImageSpace(value) {
        this.mindSettingData.mindExportImageSpace = value;
        this.saveConfig();
    }
    getMindExportImageSpace() {
        return this.mindSettingData.mindExportImageSpace;
    }

    // getNodeLongPressShake() {
    //     return this.mindSettingData.nodeLongPressShake;
    // }
    // setNodeLongPressShake( value) {
    //     this.mindSettingData.nodeLongPressShake = value;
    //     this.saveConfig();
    // }

    setDefaultMindKeyboards() {
        const mindKeyboards = new Array();
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.ADD_CHILD_NODE, "17+68", "Ctrl+D", getString(Strings.Mind_Edit_Add) + getString(Strings.Mind_Edit_Sub_Topic)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.ADD_TOP_CHILD_NODE, "17+10+13", "Ctrl+Shift+Enter", getString(Strings.Mind_Edit_Add) + getString(Strings.Mind_Edit_Top_Subtopic)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.ADD_BOTTOM_CHILD_NODE, "17+13", "Ctrl+Enter", getString(Strings.Mind_Edit_Add) + getString(Strings.Mind_Edit_Bottom_Subtopic)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.REVOKE, "17+90", "Ctrl+Z", getString(Strings.Mind_Edit_Revoke)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.RECOVERY, "17+10+90", "Ctrl+Shift+Z", getString(Strings.Mind_Edit_Restore)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.EXIT_EDIT_STATUS, "17+66", "Ctrl+B", getString(Strings.Mind_Usinghelp_Btn_28)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.MODIFY_CURRENT_NODE, "17+69", "Ctrl+E", getString(Strings.Mind_Usinghelp_Btn_11)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.DELETE, "46", "Delete", getString(Strings.Mind_Usinghelp_Btn_8)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.BACKSPACE, "8", "Backspace", getString(Strings.Mind_Usinghelp_Btn_8)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.ENLARGE_MAP, "17+107", "Ctrl++", getString(Strings.Mind_Usinghelp_Btn_16)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.SHRINK_MAP, "17+109", "Ctrl+-", getString(Strings.Mind_Usinghelp_Btn_19)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.SELECT_MASTER, "17+65", "Ctrl+A", getString(Strings.Select_Main_Node)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.COPY, "17+67", "Ctrl+C", getString(Strings.Mind_Edit_Node_Menu_Copy)));
        mindKeyboards.push(new MindKeyboard(KeyboardValueType.PASTE, "17+86", "Ctrl+V", getString(Strings.Mind_Edit_Node_Menu_Paste)));
        this.mindSettingData.mindKeyboards = mindKeyboards;
        let mindKeyboardsData = this.mindSettingData.toJSONString();
        if (this.isMacComputed) {
            mindKeyboardsData = mindKeyboardsData.replace(/Ctrl/g, "Command");
            mindKeyboardsData = mindKeyboardsData.replace("Command++", "Command+=");
        }
        localStorage.setItem('defaultMindSettingData', mindKeyboardsData);
        this.saveConfig();

    }
    setMindKeyboards(type, key, shortcutKey) {
        let size = this.mindSettingData.mindKeyboards.length;
        let findMindKeyboard = null;
        for (let index = 0; index < size; index++) {
            let cell = this.mindSettingData.mindKeyboards[index];
            if (cell.type == type) {
                findMindKeyboard = cell;
            } else if (key == cell.key) {
                return false; //有冲突快捷键
            }
        }
        if (findMindKeyboard != null) {
            findMindKeyboard.key = key;
            findMindKeyboard.shortcutKey = shortcutKey;
            this.saveConfig();
        }
        return true;
    }
    getMindKeyboards() {
        return this.mindSettingData.mindKeyboards;
    }
}
export default new MindSettingManager()