

let OrderType =  {
    Order_1:0, //1,2,3
    Order_2:1, //Ⅰ,Ⅱ,Ⅲ
    Order_3:2, //ⅰ,ⅱ,ⅲ
    Order_4:3, //A,B,C
    Order_5:4, //a,b,c
    Order_6:5, //⑴⑵⑶
    Order_7:6, //⓪①②③④⑤⑥⑦⑧⑨⑩⑪⑫⑬⑭⑮⑯⑰⑱⑲⑳㉑㉒㉓㉔㉕㉖㉗㉘㉙㉚㉛㉜㉝㉞㉟㊱㊲㊳㊴㊵㊶㊷㊸㊹㊺㊻㊼㊽㊾㊿
    Order_8:7, //一， 二，三
    
    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.Order_1;
    }
}

export default OrderType