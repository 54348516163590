import MindElementAlignmentType from "../../../../viewmodel/datatype/MindElementAlignmentType";
import TextAndTaskCalculation from "./TextAndTaskCalculation";

const { default: BaseMindElementDataCalculation } = require("./BaseMindElementDataCalculation");
const { default: Size } = require("../../../../viewmodel/core/base/Size");

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndTaskAndLatexCalculation extends TextAndTaskCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainLatextContent()) {
            return;
        }
        if (this.data.taskContent == null) {
            return;
        }
        let textMindElementContent;
        let latexContent = this.data.latexContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        if (textMindElementContent == null) {
            return;
        }
        let edgeInsets = this.getTextEdgeInsets();

        let size = this.getSize();
        let marginPoint = this.getMargin(size);
        let textEdgeInsets = this.getTextEdgeInsets();

        switch (this.data.alignmentType) {
            case MindElementAlignmentType.RIGHT:
                textMindElementContent.x =  marginPoint.left + size.getWidth() - textMindElementContent.width;
                textMindElementContent.y = marginPoint.top;

                latexContent.x = marginPoint.left + size.getWidth() - this.latexOriginalSize.getWidth() + textEdgeInsets.left;
                latexContent.y = textMindElementContent.y + textMindElementContent.height;

                this.data.taskContent.x = marginPoint.left + size.getWidth() - this.taskOriginalSize.getWidth();
                this.data.taskContent.y = latexContent.y + this.latexOriginalSize.getHeight() + textEdgeInsets.top;
                break;
            case MindElementAlignmentType.MIDDLE:
                textMindElementContent.x =  marginPoint.left + (size.getWidth() - textMindElementContent.width)/2;
                textMindElementContent.y = marginPoint.top;
                latexContent.x = marginPoint.left + (size.getWidth() - this.latexOriginalSize.getWidth())/2;
                latexContent.y = textMindElementContent.y + textMindElementContent.height;

                this.data.taskContent.x = marginPoint.left + (size.getWidth() - this.taskOriginalSize.getWidth())/2;
                this.data.taskContent.y = latexContent.y + this.latexOriginalSize.getHeight() + textEdgeInsets.top;
                break;
            default: //case LEFT
                textMindElementContent.x = marginPoint.left;
                textMindElementContent.y = marginPoint.top;

                latexContent.x = marginPoint.left + edgeInsets.left;
                latexContent.y = textMindElementContent.y + textMindElementContent.height;
                this.data.taskContent.x = marginPoint.left + edgeInsets.left;
                this.data.taskContent.y = latexContent.y + this.latexOriginalSize.getHeight() + textEdgeInsets.top;
        }

        latexContent.width = this.latexOriginalSize.getWidth();
        latexContent.height = this.latexOriginalSize.getHeight();

        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
    getSize() {
        return this.addLatexSize(super.getSize());
    }
}
export default TextAndTaskAndLatexCalculation