
/**
 * ProjectName: MindMap
 * Created by tony on 1/14/21
 * Copyright(c) 2020 mindyushu.com
 */

let StatisticsChapeType = {
    CakeShape_1:1,
    CakeShape_2:2,
    CakeShape_3:3,
    CakeShape_4:4,
    CakeShape_5:5,
    Histogram_1:6,
    Histogram_2:7,
    Histogram_3:8,
    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.CakeShape_1;
    }

}

export default StatisticsChapeType