import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";
import Size from "../../../../viewmodel/core/base/Size";
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndRemarksAndConnectMap extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }
    calculate() {
        if (!this.data.isContainRemarksContent() || !this.data.isContainConnectMapContent()) {
            return;
        }

        let textMindElementContent = this.getTextMindElementContent();
        let remarksElementContent = this.data.remarksElementContent;
        let connectMapElementContent = this.data.connectMapElementContent;

        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        remarksElementContent.width = textHeight;
        remarksElementContent.height = textHeight;
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;
        let size = this.includeTextAndRemarksAndConnectMapSize();
        let iconsSize = new Size((remarksElementContent.width + connectMapElementContent.width) + this.iconSpace, (remarksElementContent.height))
        //new Size((remarksElementContent.width + connectMapElementContent.width) + iconSpace, (remarksElementContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        if (!this.data.isContainRemarksContent()) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

        remarksElementContent.x = iconLeft + marginX;
        remarksElementContent.y = iconTop + marginY;

        connectMapElementContent.x = remarksElementContent.x + remarksElementContent.width + (this.iconSpace);
        connectMapElementContent.y = remarksElementContent.y;

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}

export default TextAndRemarksAndConnectMap
