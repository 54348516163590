// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * ProjectName: MindMap
 * Created by tony on 5/20/21
 * Copyright(c) 2020 mindyushu.com
 */

let TimeNodeLayoutType = {
    NORMAL:0, //三个节点都展示
    TITLE_DES_BOTTOM:1,
    TITLE_BOTTOM:2,
    HEAD_TITLE_TOP_BOTTOM:3,
    HEAD_TITLE_DES_MIDDLE_BOTTOM:4,
    HEAD_TITLE_MIDDLE_BOTTOM:5,

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.NORMAL;
    }

}

export default TimeNodeLayoutType
