import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndResourceCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }


    calculate() {
        if (!this.data.isContainResourceContent() || this.data.imageContent == null) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();
       
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        let resourceContent = this.data.resourceContent;
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        resourceContent.width = textHeight;
        resourceContent.height = textHeight;

        let textResourceSize = this.includeResourceAndTextSize();
        let size = this.includeImageAndTextResourceSize();

        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;
        let resourceTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2 + marginY;
            iconLeft = ((this.data.type == MindElementType.MAIN_SUBJECT || 
            this.data.type == MindElementType.SUBJECT || 
            this.data.type == MindElementType.CONTENT_GENERALIZATION ||
            this.data.type == MindElementType.EXPLAIN) ? (this.iconMargin) : 0) + marginX;
            
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            resourceContent.x = textMindElementContent.x + textMindElementContent.width + resourceTextSpace;
            resourceContent.y = ((size.getHeight()) - resourceContent.height) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin) + marginX;
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2 + marginY;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            resourceContent.x = textMindElementContent.x + textMindElementContent.width + resourceTextSpace;
            resourceContent.y = ((size.getHeight()) - resourceContent.height) / 2 + marginY;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2 + marginX;
            iconTop = marginY;
            textMindElementContent.x = ((size.getWidth()) - (textResourceSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + this.iconSpace;

            resourceContent.x = textMindElementContent.x + textMindElementContent.width + resourceTextSpace;
            resourceContent.y = textMindElementContent.y + ((textResourceSize.getHeight()) - resourceContent.height) / 2;
        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}
export default TextAndImageAndResourceCalculation