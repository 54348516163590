import Vue from 'vue'
import Router from 'vue-router'
import HomeMain from '@/components/HomeMain'
import getString from '../common/lang/language'
import strings from '../common/lang/strings'
// import { component } from 'vue/types/umd'

Vue.use(Router)
//解决重复点击路由报错
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

let isElectron = false;
let appPath = "";
if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
  isElectron = true;
  var { ipcRenderer } = window.require("electron");
  ipcRenderer.on("setAppPath", (event, path) => {
    appPath = path;
    if (router != null) {
      router.appPath = path
    }
  });
  ipcRenderer.send("getAppPath");
}
let router = new Router({
  mode: isElectron ? 'hash' : 'history',
  routes: [
    //登录
    {
      path: '/login',
      name: 'login',
      component: resolve => require(["../views/login/Login.vue"], resolve)
    },
    {
      path: '/mindmap',
      name: 'Complie',
      component: resolve => require(["../views/complie/ComPlie.vue"], resolve),
    },
    {
      path: '/download',
      name: 'download',
      component: resolve => require(["../views/download/Download.vue"], resolve),
    },
    {
      path: '/template',
      name: 'template',
      component: resolve => require(["../views/template/Template.vue"], resolve)
    },
    {
      path: '/gmap',
      name: 'gmap',
      component: resolve => require(["../views/gmap/Gmap.vue"], resolve)
    },
    {
      path: '/arvhiveMap',
      name: 'arvhiveMap',
      component: resolve => require(["../views/arvhiveMap/arvhiveMap.vue"], resolve)
    },
    {
      path: '/s/:id',
      name: 's',
      component: resolve => require(["../views/share/Share.vue"], resolve)
    },
    {
      path: '/g/:id',
      name: 'g',
      component: resolve => require(["../views/share/SharedGroupDetails.vue"], resolve)
    },
    {
      path: '/c/:id',
      name: 'c',
      component: resolve => require(["../views/crisps/Crisps.vue"], resolve)
    },
    {
      path: '/help',
      name: 'Help',
      component: resolve => require(["@/components/universalFile/Help.vue"], resolve)
    },
    {
      path: '/downLoadAlter',
      name: 'downLoadAlter',
      component: resolve => require(["@/components/universalFile/downLoadAlter.vue"], resolve)
    },
    {
      //框架
      path: '/',
      name: 'Home',
      // component: HomeTeacher
      component: resolve => require(["@/components/myFile/Home.vue"], resolve),
      children: [
        // {
        //   path: '/Home/GroupSettingPage',
        //   name: 'GroupSettingPage',
        //   component: resolve => require(["@/components/groupManagementChildren/groupSettingPage/GroupSettingPage.vue"], resolve),
        // },
        {
          path: 'GroupCreatedNewGroup',
          name: 'GroupCreatedNewGroup',
          component: resolve => require(["@/components/groupManagementChildren/GroupCreatedNewGroup.vue"], resolve),
        },
        {
          path: '/Home/GroupManagement',
          name: 'GroupManagement',
          component: resolve => require(["@/components/myFile/GroupManagement.vue"], resolve),
        },
        {
          path: '/Home/StormBookTag',
          name: 'StormBookTag',
          component: resolve => require(["@/components/myFile/StormBookTag.vue"], resolve),
        },
        {
          path: '/Home/Storm',
          name: 'Storm',
          component: resolve => require(["@/components/myFile/Storm.vue"], resolve),
        },
        {
          path: '/Home/StormSearch',
          name: 'StormSearch',
          component: resolve => require(["@/components/myFile/stormChild/StormSearch.vue"], resolve),
        },
        {
          path: '/Home/tag',
          name: 'tag',
          component: resolve => require(["@/components/myFile/stormChild/StormTag.vue"], resolve),
        },
        {
          path: '/Home/moreGroups',
          name: 'moreGroups',
          component: resolve => require(["@/components/myFile/stormChild/MoreGroups.vue"], resolve),
        },
        {
          path: '/Home/moreCrisps',
          name: 'moreCrisps',
          component: resolve => require(["@/components/myFile/stormChild/MoreCrisps.vue"], resolve),
        },
        {
          path: '/Home/Details',
          name: 'Details',
          component: resolve => require(["@/components/userMessage/UserMessageDetails.vue"], resolve),
          // meta: {
          //   keepAlive: true,
          // }
        },
        {
          path: '/Home/individualAccount',
          name: 'individualAccount',
          component: resolve => require(["@/components/userMessage/IndividualAccount.vue"], resolve),
        },
        {
          path: '/Home/moreMind',
          name: 'moreMind',
          component: resolve => require(["@/components/userMessage/userMessageData/UserShareData.vue"], resolve)
        },
        {
          path: '/Home/moreCollection',
          name: 'moreCollection',
          component: resolve => require(["@/components/userMessage/userMessageData/UserShareCollection.vue"], resolve)
        },
        {
          path: '/Home/meMoreMind',
          name: 'meMoreMind',
          component: resolve => require(["@/components/userMessage/meMessageData/MeShareData.vue"], resolve)
        },
        {
          path: '/Home/meMoreCollection',
          name: 'meMoreCollection',
          component: resolve => require(["@/components/userMessage/meMessageData/MeShareCollection.vue"], resolve)
        },
        {
          path: '/Home/HomeMain',
          name: 'HomeMain',
          component: resolve => require(["@/components/HomeMain.vue"], resolve)
        },
        {
          path: '/Home/MyModal',
          name: 'MyModal',
          component: resolve => require(["@/components/myFile/MyModal.vue"], resolve),
          meta: {
            keepAlive: true, // 需要被缓存
          }
        },
        {
          path: '/Home/MyMessage',
          name: 'MyMessage',
          component: resolve => require(["@/components/myFile/MyMessage.vue"], resolve),
          children: [
            {
              path: '/Home/MyMessage/information',
              name: 'information',
              component: resolve => require(["@/components/messageComponents/inforMation"], resolve)
            },
            {
              path: '/Home/MyMessage/announcement',
              name: 'announcement',
              component: resolve => require(["@/components/messageComponents/announcement"], resolve)
            }
          ]
        },
        {
          path: '/Home/MyMind',
          name: 'MyMind',
          component: resolve => require(["@/components/myMind/MyMind.vue"], resolve),
          meta: {
            keepAlive: true, // 需要被缓存
          }
        },
        {
          path: '/Home/CreatedFile',
          name: 'CreatedFile',
          component: resolve => require(["@/components/createdFile/CreatedFile.vue"], resolve),
          meta: {
            keepAlive: true // 需要被缓存
          }
        },
        {
          path: '/Home/Archive',
          name: 'Archive',
          component: resolve => require(["@/components/myArchive/MyArchive.vue"], resolve),
          meta: {
            // keepAlive: true // 需要被缓存
          }
        },
        {
          path: '/Home/FunctionList',
          name: 'FunctionList',
          component: resolve => require(["@/components/universalFile/FunctionList.vue"], resolve)
        },
        {
          path: '/Home/NewChange',
          name: 'NewChange',
          component: resolve => require(["@/components/universalFile/NewChange.vue"], resolve)
        },
        {
          path: '/Home/NewDelet',
          name: 'NewDelet',
          component: resolve => require(["@/components/universalFile/NewDelet.vue"], resolve)
        },
        {
          path: '/Home/SettingUp',
          name: 'SettingUp',
          component: resolve => require(["@/components/universalFile/SettingUp.vue"], resolve)
        },
        {
          path: '/Home/Help',
          name: 'Help',
          component: resolve => require(["@/components/universalFile/Help.vue"], resolve)
        },
        {
          path: '/Home/Download',
          name: 'Download',
          component: resolve => require(["@/components/universalFile/Download.vue"], resolve)
        }
      ]
    },
  ],
})
router.beforeEach((to, from, next) => {
  document.title = to.meta.title || getString(strings.About_App_Name);
  next();
});
router.appPath = appPath
export default router;