import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";
import Size from "../../../../viewmodel/core/base/Size";
import TextAndRemarksCalculation from "./TextAndRemarksCalculation";
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndRemarksAndLatexCalculation extends TextAndRemarksCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }
    calculate() {
        if (!this.data.isContainRemarksContent() || !this.data.isContainLatextContent()) {
            return;
        }

        let textMindElementContent = this.getTextMindElementContent();
        let remarksElementContent = this.data.remarksElementContent;

        if (textMindElementContent == null) {
            return;
        }

        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        remarksElementContent.width = textHeight;
        remarksElementContent.height = textHeight;
        let size = this.getContentSize();
        let remarksAndTextSize = this.includeRemarksAndTextSize();
        let iconsSize = new Size(remarksElementContent.width, remarksElementContent.height)

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        if (!this.data.isContainRemarksContent()) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (remarksAndTextSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (remarksAndTextSize.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((remarksAndTextSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

        remarksElementContent.x = iconLeft + marginX;
        remarksElementContent.y = iconTop + marginY;

        let textEdgeInsets = this.getTextEdgeInsets();
        this.data.latexContent.x = marginX + textEdgeInsets.left;
        this.data.latexContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.latexContent.width = this.latexOriginalSize.getWidth();
        this.data.latexContent.height = this.latexOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
    getContentSize() {
        return this.addLatexSize(this.includeRemarksAndTextSize());
    }
}

export default TextAndRemarksAndLatexCalculation
