let EncircleShapeType = {
   LAYOUT_TRAPEZOID:1,
   LAYOUT_RECTANGLE_1:2,
   LAYOUT_RECTANGLE_2:3,
   LAYOUT_RECTANGLE_3:4,
   LAYOUT_RECTANGLE_4:5,
    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.LAYOUT_TRAPEZOID;
    }

}
export default EncircleShapeType