import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";

const { default: BaseMindElementDataCalculation } = require("./BaseMindElementDataCalculation");

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndIconAndMoreCalculation extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {

        if (this.data.moreContent == null) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        
        let moreContent = this.data.moreContent;
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        moreContent.width = textHeight;
        moreContent.height = textHeight;

        let size = this.includeIconAndTextMoreSize();
        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let textmoreSize = this.includeMoreAndTextSize();
        let iconLeft = 0;
        let iconTop = 0;
        let moreTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == IconLayoutType.LAYOUT_LEFT) {
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconMargin) : 0;
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            moreContent.x = textMindElementContent.x + textMindElementContent.width + moreTextSpace;
            moreContent.y = ((size.getHeight()) - moreContent.height) / 2 + marginY;
        } else if (layoutType == IconLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
            iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

            moreContent.x = textMindElementContent.x + textMindElementContent.width + moreTextSpace;
            moreContent.y = ((size.getHeight()) - moreContent.height) / 2 + marginY;

        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = (this.iconSpace);
            textMindElementContent.x = ((size.getWidth()) - (textmoreSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            moreContent.x = textMindElementContent.x + textMindElementContent.width + moreTextSpace;
            moreContent.y = textMindElementContent.y + ((textmoreSize.getHeight()) - moreContent.height) / 2;

        }
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = iconLeft + marginX;
            } else {
                iconCell.x = iconElementContents[index - 1].x + iconElementContents[index - 1].width + (this.iconSpace);
            }
            iconCell.y = iconTop + marginY;
        }
        // this.setMindElementSize(size.getWidth(), size.getHeight(), marginX, marginY);
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}
export default TextAndIconAndMoreCalculation
