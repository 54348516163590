// package mind.yushu.com.mindmap.core.calcule.elementCalculation;

import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation";
import CakeShapeCalculation from "./widget/CakeShapeCalculation";
import ColumnarCalculation from "./widget/ColumnarCalculation";
import StatisticsChapeType from "../../../../viewmodel/datatype/StatisticsChapeType";
// import android.graphics.Point;
// import android.util.Size;

// import mind.yushu.com.mindmap.core.calcule.elementCalculation.widget.CakeShapeCalculation;
// import mind.yushu.com.mindmap.core.calcule.elementCalculation.widget.ColumnarCalculation;
// import mind.yushu.com.mindmap.viewmodel.datatype.StatisticsChapeType;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.MindElementContent;

/**
 * ProjectName: MindMap
 * Created by tony on 1/12/21
 * Copyright(c) 2020 mindyushu.com
 */
class StatisticsCalculation extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }
    // public StatisticsCalculation(MindElementData data) {
    //     super(data);
    // }

    calculate() {
        if (!this.data.isStatisticsContent()) {
            return;
        }
        if (this.data.statisticsContent.type == null) {
            this.data.statisticsContent.type = StatisticsChapeType.CakeShape_1;
        }
        switch (this.data.statisticsContent.type) {
            case StatisticsChapeType.Histogram_1:
            case StatisticsChapeType.Histogram_2:
            case StatisticsChapeType.Histogram_3:
                new ColumnarCalculation(this.data).calculate();
                break;
            case StatisticsChapeType.CakeShape_1:
            case StatisticsChapeType.CakeShape_2:
            case StatisticsChapeType.CakeShape_3:
            case StatisticsChapeType.CakeShape_4:
            case StatisticsChapeType.CakeShape_5:
                new CakeShapeCalculation(this.data).calculate();
                break;
            default:
                new CakeShapeCalculation(this.data).calculate();

        }
    }
}

export default StatisticsCalculation