import Size from "../../../../viewmodel/core/base/Size";

const { default: BaseMindElementDataCalculation } = require("./BaseMindElementDataCalculation");

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndResourceCalculation extends BaseMindElementDataCalculation {
    constructor(data, nodeLayoutType) {
        super(data, nodeLayoutType)
    }

    calculate() {
        if (!this.data.isContainResourceContent()) {
            return;
        }
        let textMindElementContent;
        let resourceContent = this.data.resourceContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        } else {
            return;
        }
        let textRext = this.caluleTextCGRect();

        let textHeight = textRext.height();
        resourceContent.width = textHeight;
        resourceContent.height = textHeight;
        let size = this.includeResourceAndTextSize();
        let iconsSize = new Size(resourceContent.width, resourceContent.height);

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;
        
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

        resourceContent.x = iconLeft + marginX;
        resourceContent.y = iconTop + marginY;

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}
export default TextAndResourceCalculation
