// package mind.yushu.com.mindmap.viewmodel.defer;

// import java.util.HashMap;
// import java.util.Map;

// import mind.yushu.com.mindmap.viewmodel.mindelementdata.LineMindTypeNode;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;

import HashMap from "../../viewmodel/core/base/HashMap"

/**
 * Created by tony on 2019/12/26
 */

class MindDataLayoutDelegate {
    callbackResetDatas(rootTreeNode,
        lineMindElementDataDict,
        mainMindElementDataDict,
        textElementLineMindElementDataDict,
        generalizationLineMindElementDataDict,
        generalizationMindElementDataDict,
        explainMindElementDataDict){};
}
export default MindDataLayoutDelegate
