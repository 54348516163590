<template>
 <a-layout id="components-layout-demo-side "  style="min-height: 100vh">
    <a-layout-sider class="menu-big-box" v-model="collapsed" collapsible>
      <!-- <div style="color: red">3333</div> -->
      <!-- <div class="logo">
        123
      </div> -->
      <div class="add-file">
        <a-popover placement="bottom">
        <template slot="content">
          <p>思维导图</p>
          <p>文件夹</p>
          <p>导图模板</p>
        </template>
  
        <a-button>+ 添加文件</a-button>
      </a-popover>
      </div>
      <a-menu class="ant-menu-inline-all" theme="dark" :default-selected-keys="['1']" mode="inline">
        <a-menu-item key="1">
          <a-icon type="pie-chart" />
          <span>Option 1</span>
        </a-menu-item>
        <a-menu-item key="2">
          <a-icon type="desktop" />
          <span>Option 2</span>
        </a-menu-item>
        <a-sub-menu  key="sub1">
          <span slot="title"><a-icon type="user" /><span>User</span></span>
          <a-menu-item  key="3">
            Tom
          </a-menu-item>
          <a-menu-item key="4">
            Bill
          </a-menu-item>
          <a-menu-item key="5">
            Alex
          </a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub2">
          <span slot="title"><a-icon type="team" /><span>Team</span></span>
          <a-menu-item key="6">
            Team 1
          </a-menu-item>
          <a-menu-item key="8">
            Team 2
          </a-menu-item>
        </a-sub-menu>
        <a-menu-item key="9">
          <a-icon type="file" />
          <span>File</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <!-- <a-layout-header style="background: #fff; padding: 0" />
    </a-layout> -->
    <a-layout-sider class="left-background">
       <div  class="left-content" >
            <div class="left-top">
              <a-icon class="content-small-icon" type="rollback" />
              <a-popover placement="bottom">
                <template slot="content">
                  <p>时间升序</p>
                  <p>时间降序</p>
                  <p>字母升序</p>
                  <p>字母降序</p>
                </template>
          
                <a-icon class="content-small-icon" type="bars" />
              </a-popover>
              
            </div>
            <div class="list-mind-map">
              <div class="mind-map-img">  </div>
              <div class="mind-map-name">
                思维导图名字 思维导图名字 思维导图名字
              </div>
              <div><a-icon type="pie-chart" /></div>
            </div>
                        <div class="list-mind-map">
              <div class="mind-map-img">  </div>
              <div class="mind-map-name">
                思维导图名字 思维导图名字 思维导图名字
              </div>
              <div><a-icon type="pie-chart" /></div>
            </div>
                        <div class="list-mind-map">
              <div class="mind-map-img">  </div>
              <div class="mind-map-name">
                思维导图名字 思维导图名字 思维导图名字
              </div>
              <div><a-icon type="pie-chart" /></div>
            </div>
                        <div class="list-mind-map">
              <div class="mind-map-img">  </div>
              <div class="mind-map-name">
                思维导图名字 思维导图名字 思维导图名字
              </div>
              <div><a-icon type="pie-chart" /></div>
            </div>
                        <div class="list-mind-map">
              <div class="mind-map-img">  </div>
              <div class="mind-map-name">
                思维导图名字 思维导图名字 思维导图名字
              </div>
              <div><a-icon type="pie-chart" /></div>
            </div>
                        <div class="list-mind-map">
              <div class="mind-map-img">  </div>
              <div class="mind-map-name">
                思维导图名字 思维导图名字 思维导图名字
              </div>
              <div><a-icon type="pie-chart" /></div>
            </div>
                        <div class="list-mind-map">
              <div class="mind-map-img">  </div>
              <div class="mind-map-name">
                思维导图名字 思维导图名字 思维导图名字
              </div>
              <div><a-icon type="pie-chart" /></div>
            </div>
        </div>
    </a-layout-sider>

     <a-layout class="content" >
        <div :style="'width:'+fullWidth+'px;'" class="content-header">
            <a-avatar class='header-img' :size="45">
              <a-icon slot="icon" type="user" />
            </a-avatar>
        </div>

      <a-layout-content  style="margin: 0">

        <div ref="chat" :style="'height:'+fullHeight+'px;'" class="right-content">

          <div class="right-content-child">
            内容
          </div>
        </div>
        <!-- <div >
        <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>User</a-breadcrumb-item>
          <a-breadcrumb-item>Bill</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
          Bill is a cat.
        </div>
        </div> -->

      </a-layout-content>
       </a-layout>
      <!-- <a-layout-footer style="text-align: center">
        Ant Design ©2018 Created by Ant UED
      </a-layout-footer> -->
    </a-layout>
  </a-layout>
</template>

<script>
export default {
  data() {
    return {
      collapsed: false,
      fullHeight: document.documentElement.clientHeight - 64,
      fullWidth:document.documentElement.clientWidth - 400
    };
  },
  created(){
     this.get_bodyWidth()

     
  },
      mounted () {
// const ro = new ResizeObserver((entries, observer) => {
//     for (const entry of entries) {
//         const {left, top, width, height} = entry.contentRect;
//         this.fullWidth = width
//     }
// });
 
// ro.observe(this.$refs.chat);
//     },
//   methods: {
//     get_bodyWidth () {//动态获取浏览器宽度
//             const that = this
//             window.onresize = () => {
//                 return (() => {
//                     that.fullHeight = document.documentElement.clientHeight - 64
//                     that.fullWidth = document.documentElement.clientWidth - 400
//                 })()
//             }
//         }
  },
  watch:{
    // fullHeight (val) {//监控浏览器高度变化
    //         if (!this.width_timer) {
    //             this.fullHeight = val
    //             this.width_timer = true
    //             let that = this
    //             setTimeout(function () {
    //                 that.width_timer = false
    //             }, 400)
    //         }
    //     }
  }
};
</script>

<style scoped>
#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.menu-big-box{
  background-color:orange;
}

.ant-menu-inline-all{
background-color:orange;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub{
  background-color:orange;
}

/* .ant-menu-submenu {
background-color:orange;
}
.ant-menu-submenu-vertical{
background-color:orange;
} */

.ant-layout-sider-trigger{
  background-color: orange;
}

.add-file{
  text-align: center;
  height: 64px;
  line-height: 64px;
}



.content{
  /* display: flex;
  align-items: flex-start; */
  /* position: relative; */
}
.content-header{
  position: fixed;
  top: 0;
  height: 64px;
  width: 100%;
  background-color: #eee;
}
.header-img{
  float: right;
  margin-right: 10px;
  margin-top: 9px;
}
/* 内容的左边 */
.left-background{
  background-color: cornflowerblue;
}
.left-content{
  /* height: 100vh; */
  width: 200px;
  background-color: #999;
  /* flex: 0 0 auto; */
}
.left-top{
  height: 64px;
  border-right: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
    padding: 20px;
}
.content-small-icon{
  font-size: 25px;
  color: #999;
}


.list-mind-map{
  height: 70px;
  background-color: lightsalmon;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
.mind-map-img{
  width: 45px;
  height: 45px;
  box-sizing: border-box;
  background-color:linen;
}
.mind-map-name{
  width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* 内容的右边 */

/* 头部css */
.header-box{
  height: 64px;
  background-color: #fff;

}
/* 头像 */
.head-image{
  float: right;
  margin-top: 16px;
  margin-right: 16px;
}


.right-content{
  margin-top: 64px;
  /* flex: 1 1 auto; */
  background-color: lightpink;
  /* height: 100vh; */
}

.right-content-child{
  width: 200px;
  height: 2000px;
}

</style>
