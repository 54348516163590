import Point from "../../../../viewmodel/core/base/Point"
import Size from "../../../../viewmodel/core/base/Size"
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType"
import MindElementType from "../../../../viewmodel/datatype/MindElementType"
import MindElementData from "../../../../viewmodel/mindelementdata/MindElementData"
import IconElementContent from "../../../../viewmodel/mindelementdata/mindcontent/IconElementContent"
import MindElementContent from "../../../../viewmodel/mindelementdata/mindcontent/MindElementContent"
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import TextAndIconCalculation from "./TextAndIconCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */
class TextAndIconAndTaskCalculation extends TextAndIconCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        let taskContent = this.data.taskContent;
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null || taskContent == null) {
            return;
        }

        let size = this.includeIconAndTextAndTaskSize();
        let iconAndTextSize = this.includeIconAndTextSize();
        let marginPoint = this.getMargin(size);

        let layoutType = this.getIconLayout();
        let iconsSize = this.caluleIconElementSize();

        let marginX = marginPoint.left;
        let marginY = marginPoint.top;
        let textEdgeInsets = this.getTextEdgeInsets();

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length== 0) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            this.data.taskContent.x = marginPoint.left + textEdgeInsets.left;
            this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        if (layoutType == IconLayoutType.LAYOUT_LEFT) {
            iconLeft = this.iconMargin;
            iconTop = (iconAndTextSize.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((iconAndTextSize.getHeight()) - textMindElementContent.height) / 2 + marginY;
            if (iconsSize.getHeight() > textMindElementContent.height) {
                this.data.taskContent.y = iconTop + iconsSize.getHeight() + marginY;
            } else {
                this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
            }
        } else if (layoutType == IconLayoutType.LAYOUT_RIGHT) {
            iconLeft = (iconAndTextSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
            iconTop = (iconAndTextSize.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((iconAndTextSize.getHeight()) - textMindElementContent.height) / 2 + marginY;
            if (iconsSize.getHeight() > textMindElementContent.height) {
                this.data.taskContent.y = iconTop + iconsSize.getHeight() + marginY;
            } else {
                this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
            }
        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            iconLeft = (iconAndTextSize.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = (this.iconSpace);
            textMindElementContent.x = ((iconAndTextSize.getWidth()) - textMindElementContent.width) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;
            this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
        }
        this.data.taskContent.x = marginPoint.left + textEdgeInsets.left;
        for (let index = 0; index < iconElementContents.length ; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = iconLeft + marginX;
            } else {
                iconCell.x = iconElementContents[index-1].x + iconElementContents[index-1].width + (this.iconSpace);
            }
            iconCell.y = iconTop + marginY;
        }
        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();
        // this.setMindElementSize(size.getWidth(), size.getHeight(), marginX, marginY);
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}

export default TextAndIconAndTaskCalculation