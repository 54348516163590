import TextAndLinkAndRemarksCalculation from "./TextAndLinkAndRemarksCalculation";

const { default: BaseMindElementDataCalculation } = require("./BaseMindElementDataCalculation");
const { default: Size } = require("../../../../viewmodel/core/base/Size");

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */
 
 class TextAndLinkAndRemarksAndTaskCalculation extends TextAndLinkAndRemarksCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }
     calculate() {
        if (!this.data.isContainLinkContent() || !this.data.isContainRemarksContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let linkElementContent = this.data.linkElementContent;
        let remarksElementContent = this.data.remarksElementContent;
        
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        linkElementContent.width = textHeight;
        linkElementContent.height = textHeight;
        remarksElementContent.width = textHeight;
        remarksElementContent.height = textHeight;

        let size = this.includeTextAndLinkAndRemarksAndTaskSize();
        let textAndLinkAndRemarksSize = this.includeTextAndLinkAndRemarksSize();
        let iconsSize = new Size((linkElementContent.width + remarksElementContent.width) + this.iconSpace, (linkElementContent.height));
        
        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        if (!this.data.isContainLinkContent()) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (textAndLinkAndRemarksSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (textAndLinkAndRemarksSize.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((textAndLinkAndRemarksSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

        linkElementContent.x = iconLeft + marginX;
        linkElementContent.y = iconTop + marginY;

        remarksElementContent.x = linkElementContent.x + linkElementContent.width + (this.iconSpace);
        remarksElementContent.y = linkElementContent.y;

        let textEdgeInsets = this.getTextEdgeInsets();
        this.data.taskContent.x = marginX + textEdgeInsets.left;
        this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}
export default TextAndLinkAndRemarksAndTaskCalculation
