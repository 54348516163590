import Config from "../../../core/core/calcule/Config";
import UiUtil from "../../../utils/UiUtil";
import Util from "../../../utils/Util";
import MindSectionChangeData from "./MindSectionChangeData";

class MindEditSectionId {
    constructor() {
        this.uuid = "";
        this.startId = 0;
        this.idCount = 5000;
        this.removeList = [];
        this.changeList = [];
    }
    
    copy() {
        let data = new MindEditSectionId();
        data.uuid = this.uuid;
        data.startId = this.startId;
        data.idCount = this.idCount;
        let removeList = [];
        let changeList = [];
        if (this.removeList != null) {
            for (let index = 0; index < this.removeList.length; index++) {
                let cell = this.removeList[index];
                removeList.push(cell.copy());
            }
        }
        data.removeList = removeList;
        if (this.changeList != null) {
            for (let index = 0; index < this.changeList.length; index++) {
                let cell = this.changeList[index];
                changeList.push(cell.copy());
            }
        }
        data.changeList = changeList;
        return data;
     }

    equal(data) {
        if (data == null) {
            return false;
        }
        var result = false;
        result = result || data.uuid != this.uuid;
        result = result || data.startId != this.startId;
        result = result || data.idCount != this.idCount;

        let removeList = [];
        let changeList = [];
        if (this.removeList != null) {
            for (let index = 0; index < this.removeList.length; index++) {
                let cell = this.removeList[index];
                removeList.push(cell.copy());
            }
        }
        data.removeList = removeList;
        if (this.changeList != null) {
            for (let index = 0; index < this.changeList.length; index++) {
                let cell = this.changeList[index];
                changeList.push(cell.copy());
            }
        }
        data.changeList = changeList;

        return !result;
    }
  
     addRemoveData(id) {
        if (this.removeList == null) {
           this.removeList = [];
        }
        for (let index = 0; index < this.removeList.length; index++) {
           if (this.removeList[index].id == id) {
              this.removeList[index].time = Util.getCurrentTimeInsecond();
              return;
           }
        }
        let data = new MindSectionChangeData();
        data.time = Util.getCurrentTimeInsecond();
        data.id = id;
        this.removeList.push(data);
     }
  
     addChangeData(id) {
        if (this.changeList == null) {
           this.changeList = [];
        }
        for (let index = 0; index < this.changeList.length; index++) {
           if (this.changeList[index].id == id) {
              this.changeList[index].time = Util.getCurrentTimeInsecond();
              return;
           }
        }
        let data = new MindSectionChangeData();
        data.time = Util.getCurrentTimeInsecond();
        data.id = id;
        this.changeList.push(data);
     }
  
     merged(data) {
        if (this.uuid == null || this.uuid.length == 0) {
           this.uuid = data.uuid;
           this.startId = data.startId;
           this.idCount = data.idCount;
           this.removeList = data.removeList;
           this.changeList = data.changeList;
        } else if (this.uuid == data.uuid) {
           if (this.startId < data.startId) {
              this.startId = data.startId;
              this.idCount = data.idCount;
           }
           let time = Util.getCurrentTimeInsecond();
           if (data.removeList != null && !data.removeList.isEmpty()) {
                let list = [];
              if (this.removeList != null && !this.removeList.isEmpty()) {
                 for (let index = 0; index < this.removeList.length; index++) {
                    if (time - this.removeList[index].time > 30) {
                       continue;
                    }
                    list.push(this.removeList[index]);
                 }
              }
              for (let index = 0; index < data.removeList.length; index++) {
                let cell = data.removeList[index];
                 if (time - cell.time > 30) {
                    continue;
                 }
                 let find = false;
                 for (let j = 0; j < list.length; j++) {
                    if (list[j].id == cell.id) {
                       list[j].merged(cell);
                       find = true;
                       break;
                    }
                 }
                 if (!find) {
                    list.push(cell.copy());
                 }
              }
              this.removeList = list;
           }
           if (data.changeList != null && !data.changeList.isEmpty()) {
                let list = [];
              if (this.changeList != null && !this.changeList.isEmpty()) {
                 for (let index = 0; index < this.changeList.length; index++) {
                    if (time - this.changeList[index].time > 30) {
                       continue;
                    }
                    list.push(this.changeList[index]);
                 }
              }
              for (let index = 0; index < data.changeList.length; index++) {
                let cell = data.changeList[index];
                 if (time - cell.time > 30) {
                    continue;
                 }
                 let find = false;
                 for (let j = 0; j < list.length; j++) {
                    if (list[j].id == cell.id) {
                       list[j].merged(cell);
                       find = true;
                       break;
                    }
                 }
                 if (!find) {
                    list.push(cell.copy());
                 }
              }
              this.changeList = list;
           }
        }
     }
 }
 export default MindEditSectionId