import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class ImageCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (this.data.imageContent == null) {
            return;
        }
        let imageContent = this.data.imageContent;
        imageContent.x = 0;
        imageContent.y = 0;
        this.data.width = imageContent.width;
        this.data.height = imageContent.height;
        this.setCustomSize(this.data.customWidth);
    }
}

export default ImageCalculation