<template>
  <main id="password-model" @click.stop="closeModelBox()">
    <section class="password-box" @click.stop="">
      <div class="model-head">
        <div class="head-title">
          {{ modelHeadTitle }}
        </div>
        <div class="close-model">
          <img
            class="close-button-img"
            src="../../../../assets/img/popupsImg/colse_new.svg"
            @click.stop="closeModelBox()"
          />
        </div>
      </div>
      <section class="password-container" @click.stop="">
        <div class="account-numeber">
          <template v-if="showType == 'phoneType'">
            <div class="drop-down">
              <country-code-selector
                :countryCode.sync="countryCode"
                class="qudeng"
              ></country-code-selector>
            </div>
            <!-- 手机号 -->
            <input
              type="tel"
              id="phoneNumber"
              class="input-style"
              :placeholder="
                getString(strings.User_Lolgin_Please_Enter_Phone_Number)
              "
              style="width: 100%; padding-left: 33%"
              v-model="phoneNumberVal"
              maxlength="11"
              @input="inputForValidateCellNumber"
              @focus="focusSelect($event)"
            />
          </template>
          <template v-else-if="showType == 'emailType'">
            <!-- 邮箱 -->
            <input
              type="email"
              id="mailbox"
              class="input-style"
              :placeholder="getString(strings.Contact_Us_Email)"
              style="width: 100%"
              v-model="mailboxVal"
              @input="inputForValidatemailbox"
              @focus="focusSelect($event)"
            />
          </template>
        </div>
        <div class="verification-code">
          <!-- 验证码 -->
          <input
            type="text"
            id="verificationCode"
            class="input-style"
            :placeholder="
              getString(strings.Input_Please_Enter_Verification_Code)
            "
            style="width: 218px"
            v-model="verificationCodeVal"
            maxlength="6"
            @input="inputForValidateVerificationCode"
          />
          <div @click="getVerificationCode()">
            <a-button class="dra-btn" disabled v-if="showTimeData">
              {{ timeData }}
              <span>s</span>
            </a-button>
            <a-button
              class="dra-btn"
              :class="canGetVerificationCode ? 'can-use' : 'out-of-commission'"
              v-else
            >
              {{ getString(strings.Get_Verification_Code) }}
            </a-button>
          </div>
        </div>
        <div class="password-numeber margin-top-12">
          <input
            type="password"
            style="width: 100%"
            class="input-style"
            :placeholder="passwordPrompt"
            v-model="newPassword"
            @blur="blurForValidateNewPassword"
            @focus="focusSelect($event)"
          />
          <!-- {{placeholder="密码必须由8-24位的“字母+数字”组成"  }} -->
        </div>
        <div class="password-numeber margin-top-12">
          <input
            type="password"
            style="width: 100%"
            class="input-style"
            :placeholder="getString(strings.User_Lolgin_Confirm_Password)"
            v-model="repeatPassword"
            @blur="blurForValidateRepeatPassword"
            @focus="focusSelect($event)"
          />
        </div>

        <a-button class="confirm-button" type="primary" @click="handleSubmit">
          {{ getString(strings.Global_Ok) }}
        </a-button>
      </section>
    </section>
  </main>
</template>

<script>
import countryCodeSelector from "vue-country-code-selector";
import strings from "../../../../common/lang/strings";
import getString from "../../../../common/lang/language";
import {
  postUserChangePasswordCaptcha,
  postUserEmailCaptcha,
  postUserChangePassword,
  postUserEmailResetPassword,
} from "../../../../common/netWork/base_api";
import regExp from "../../../loginComponents/regular.js";
const { phoneNumberRegExp, mailboxRegExp, passwordRegExp, iCaptcha } = regExp;
export default {
  components: {
    countryCodeSelector, //国家区号
  },
  props: {
    showType: {
      type: String,
      defualt() {
        return "phoneType"; //密码弹框弹框显示类型，emailType：邮箱修改密码；phoneType：手机号修改密码
      },
    },
  },
  data() {
    return {
      strings,
      modelHeadTitle: getString(strings.Change_Password), //修改密码
      countryCode: 86, //国家区号
      phoneNumberVal: "", //手机号
      mailboxVal: "", //邮箱
      verificationCodeVal: "", //验证码
      newPassword: "", //新密码
      repeatPassword: "", //确认密码
      canGetVerificationCode: false, //能够获取验证码，手机号验证通过
      verificationCodePassed: false, //验证码验证通过
      newPasswordVerificationPassed: "", //新密码验证通过
      canSubmit: false, //能够提交，确认密码验证通过
      timeData: "",
      showTimeData: false,
      passwordPrompt: getString(strings.User_Lolgin_Please_Enter_New_Password), //密码提示
    };
  },
  created() {
    const jsSrc = (
      navigator.language || navigator.browserLanguage
    ).toLowerCase();
    if (jsSrc.indexOf("zh") >= 0) {
      this.passwordPrompt = "密码必须由8-24位的“字母+数字”组成";
    }
  },
  methods: {
    getString(i) {
      return getString(i);
    },
    //得到焦点选中内容
    focusSelect(event) {
      event.currentTarget.select();
    },
    inputForValidateCellNumber() {
      // 手机号验证
      this.phoneNumberVal = this.phoneNumberVal.replace(/[^\d]/g, ""); //只允许输入数字
      let value = this.phoneNumberVal;
      if (value) {
        if ("" + this.countryCode == "86") {
          let iphoneNumber = phoneNumberRegExp;
          if (iphoneNumber.test(value)) {
            this.setGetVerificationCode(true);
          } else {
            this.setGetVerificationCode(false);
          }
        }
      } else {
        this.setGetVerificationCode(false);
      }
    },
    inputForValidatemailbox() {
      // 邮箱验证
      let value = this.mailboxVal;
      if (value) {
        let imailbox = mailboxRegExp;
        if (imailbox.test(value)) {
          this.setGetVerificationCode(true);
        } else {
          this.setGetVerificationCode(false);
        }
      } else {
        this.setGetVerificationCode(false);
      }
    },
    setGetVerificationCode(val) {
      this.canGetVerificationCode = val;
    },
    setverificationCode(val) {
      this.verificationCodePassed = val;
    },
    setNewPasswordVerification(val) {
      this.newPasswordVerificationPassed = val;
      if (!val) {
        this.$message.error(
          getString(strings.Input_The_Password_Format_Is_Incorrect)
        );
      }
    },
    setCanSubmit(val) {
      this.canSubmit = val;
      if (!val) {
        this.$message.error(
          getString(strings.Input_The_Passwords_Are_Inconsistent)
        );
      }
    },
    inputForValidateVerificationCode() {
      // 验证码验证
      this.verificationCodeVal = this.verificationCodeVal.replace(/[^\d]/g, ""); //只允许输入数字
      let value = this.verificationCodeVal;
      if (value) {
        let iVerificationCode = iCaptcha;
        if (iVerificationCode.test(value)) {
          this.setverificationCode(true);
        } else {
          this.setverificationCode(false);
        }
      } else {
        this.setverificationCode(false);
      }
    },
    blurForValidateNewPassword() {
      // 新密码验证
      let value = this.newPassword;
      if (value) {
        let ipassword = passwordRegExp;
        if (ipassword.test(value)) {
          this.setNewPasswordVerification(true);
        } else {
          this.setNewPasswordVerification(false);
        }
      } else {
        this.setNewPasswordVerification(false);
      }
    },
    blurForValidateRepeatPassword() {
      // 确认密码验证
      let value = this.repeatPassword;
      let newValue = this.newPassword;
      if (value && newValue) {
        if (value == newValue) {
          this.setCanSubmit(true);
        } else {
          this.setCanSubmit(false);
        }
      } else {
        this.setCanSubmit(false);
      }
    },
    getVerificationCode() {
      if (this.showType == "phoneType") {
        // 获取手机号验证码
        if (!this.canGetVerificationCode) {
          this.$message.error(
            getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!"
          );
          return;
        }
        const SignInData = {
          areaCode: "+" + this.countryCode,
          phoneNumber: this.phoneNumberVal,
        };
        if (SignInData.areaCode && SignInData.phoneNumber) {
          postUserChangePasswordCaptcha(
            SignInData,
            (res) => {
              if (res == false) {
                this.showTimeData = res;
              } else {
                this.showTimeData = true;
                this.timeData = 60;
                let countDown = setInterval(() => {
                  if (this.timeData < 1) {
                    this.timeData = 0;
                    this.showTimeData = false;
                    clearInterval(countDown);
                  } else {
                    this.timeData--;
                  }
                }, 1000);
              }
            },
            (error) => {
              this.$message.error(error.desc);
            }
          );
        }
      } else if (this.showType == "emailType") {
        // 获取邮箱验证码
        if (!this.canGetVerificationCode) {
          this.$message.error(getString(strings.Input_Email_isNull) + "!");
          return;
        }
        const SignInData = {
          email: this.mailboxVal,
        };
        if (SignInData.email) {
          postUserEmailCaptcha(
            SignInData,
            (res) => {
              if (res == false) {
                this.showTimeData = res;
              } else {
                this.showTimeData = true;
                this.timeData = 60;
                let countDown = setInterval(() => {
                  if (this.timeData < 1) {
                    this.timeData = 0;
                    this.showTimeData = false;
                    clearInterval(countDown);
                  } else {
                    this.timeData--;
                  }
                }, 1000);
              }
            },
            (error) => {
              this.$message.error(error.desc);
            }
          );
        }
      }
    },
    handleSubmit() {
      if (this.showType == "phoneType") {
        // 手机号修改密码
        if (!this.canGetVerificationCode) {
          this.$message.error(
            getString(strings.Input_Please_Enter_A_Valid_Phone_Number) + "!"
          );
          return;
        } else if (!this.verificationCodePassed) {
          this.$message.error(
            getString(
              strings.Input_The_Please_Enter_The_Correct_Verification_Code
            )
          );
          return;
        } else if (!this.newPasswordVerificationPassed) {
          this.$message.error(
            getString(strings.Input_The_Password_Format_Is_Incorrect)
          );
          return;
        } else if (!this.canSubmit) {
          this.$message.error(
            getString(strings.Input_The_Passwords_Are_Inconsistent)
          );
          return;
        }
        const SignInData = {
          newPassword: this.newPassword,
          captcha: this.verificationCodeVal,
        };
        if (SignInData.newPassword && SignInData.captcha) {
          postUserChangePassword(
            SignInData,
            (res) => {
              this.$message.success(
                getString(strings.Message_Tips_Modification_Success)
              );
              this.closeModelBox();
            },
            (error) => {
              this.$message.error(error.desc);
            }
          );
        }
      } else if (this.showType == "emailType") {
        // 邮箱修改密码
        if (!this.canGetVerificationCode) {
          this.$message.error(getString(strings.Input_Email_isNull) + "!");
          return;
        } else if (!this.verificationCodePassed) {
          this.$message.error(
            getString(
              strings.Input_The_Please_Enter_The_Correct_Verification_Code
            )
          );
          return;
        } else if (!this.newPasswordVerificationPassed) {
          this.$message.error(
            getString(strings.Input_The_Password_Format_Is_Incorrect)
          );
          return;
        } else if (!this.canSubmit) {
          this.$message.error(
            getString(strings.Input_The_Passwords_Are_Inconsistent)
          );
          return;
        }
        const SignInData = {
          password: this.newPassword,
          captcha: this.verificationCodeVal,
          email: this.mailboxVal,
        };
        if (SignInData.password && SignInData.captcha && SignInData.email) {
          postUserEmailResetPassword(
            SignInData,
            (res) => {
              this.$message.success(
                getString(strings.Message_Tips_Modification_Success)
              );
              this.closeModelBox();
            },
            (error) => {
              this.$message.error(error.desc);
            }
          );
        }
      }
    },
    closeModelBox() {
      //关闭弹窗
      this.$emit("passwordModelfun", false);
    },
  },

  watch: {},
};
</script>

<style lang="less" scoped>
#password-model {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  .password-box {
    width: 382px;
    height: auto;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3000;
    cursor: auto;
    .model-head {
      width: 100%;
      height: 36px;
      position: relative;
      background: #efefef;
      border-radius: 4px 4px 0 0;
      padding-left: 24px;
      display: flex;
      align-items: center;
      .head-title {
        font-size: 16px;
        color: #333333;
      }
      .close-model {
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: inherit;
        display: flex;
        justify-content: center;
        align-items: center;
        .close-button-img {
          width: 16px;
          cursor: pointer;
          opacity: 0.5;
          &:hover {
            opacity: 1;
          }
        }
      }
    }
    .password-container {
      width: 100%;
      padding: 24px;
      .input-style {
        height: 40px;
        background: #efefef;
        border-radius: 4px;
        border: 0;
        outline: none;
        padding: 10px;
      }
      .account-numeber {
        width: 100%;
        position: relative;
        .drop-down {
          position: absolute;
          z-index: 5000;
          top: 0;
          left: 0;
        }
      }
      .password-numeber {
        width: 100%;
        position: relative;
      }
      .verification-code {
        width: 100%;
        position: relative;
        display: flex;
        margin-top: 12px;
        .dra-btn {
          width: 100px;
          height: 40px;
          font-size: 14px;
          border-radius: 4px;
          margin-left: 16px;
          overflow: hidden;
        }
        .can-use {
          background: #fd492b;
          color: #fff;
          border: none;
        }
        .out-of-commission {
          color: #999999;
          background: #efefef;
        }
      }
      .confirm-button {
        width: 100%;
        height: 44px;
        margin-top: 30px;
        border-radius: 4px;
        font-size: 16px;
      }
    }
  }
}
</style>