import { getMachineId } from "../../../common/netWork/base";
import SettingData from "../minddata/SettingData";

class IdGeneratorIpm {

    constructor() {
        this.INVALID_ID = -1,
        this.ROOT_ID = 0,
        this.ID_SECTION_COUNT = 5000;
        this.id = 0,
        this.shared = this
        this.existIds = [];
        this.settingData = new SettingData();
        this.deviceUuid = "";
    }

    getId() {
        let sectionId = this.getSelfSectionId();
        if (sectionId == null) {
            this.id += 1;
        } else {
            this.existIdsSort();
            if (this.id < sectionId.startId) {
                this.id = sectionId.startId;
            } else {
                let finalized = false;
                let notAddedID = true;
                for (let index = 0; index < this.existIds.length; index++) {
                    let cell = this.existIds[index];
                    if (cell < sectionId.startId) {
                        continue;
                    }
                    if (notAddedID && cell >= sectionId.startId && cell < sectionId.startId + sectionId.idCount) {
                        notAddedID = false;
                    }
                    if (index < this.existIds.length - 1) {
                        let cellNext = this.existIds[index + 1];
                        if (cell + 1 < cellNext) {
                            if (cell < sectionId.startId + sectionId.idCount) {
                                this.id = cell + 1;
                                finalized = true;
                                break;
                            }
                        }
                    } else {
                        if (cell < sectionId.startId + sectionId.idCount) {
                            this.id = cell + 1;
                            finalized = true;
                            break;
                        }
                    }
                }
                if (!finalized) {
                    if (notAddedID) {
                        this.id = sectionId.startId;
                    } else {
                        this.id += 1;
                    }

                }
            }
        }
        if (!this.existIds.includes(this.id)) {
            this.existIds.push(this.id);
        }
        return this.id;
    }

    getRootId() {
        return this.ROOT_ID;
    }

    resetId() {
        this.id = this.ROOT_ID;
        this.existIds = [];
    }

    setId(id) {
        if (this.id < id) {
            this.id = id;
        }
        if (!this.existIds.includes(id)) {
            this.existIds.push(id);
        }
    }

    getMaxId() {
        this.existIdsSort();
        if (this.existIds.length > 0) {
            return this.existIds[this.existIds.length - 1];
        }
        return this.id;
    }

    setSettingData(data) {
        this.settingData = data;
    }

    getSelfSectionId() {
        if (this.deviceUuid == null || this.deviceUuid.length == 0) {
            this.deviceUuid = getMachineId();
        }
        for(let index = 0; index < this.settingData.sectionIds.length; index++) {
            let cell = this.settingData.sectionIds[index];
            if (cell.uuid == this.deviceUuid) {
                return cell;
            }
        }
        return null;
    }

    existIdsSort() {
        this.existIds.sort(function (data1, data2) {
            if (data1 == data2) {
                return 0;
            } else if (data1 < data2) {
                return -1;
            } else {
                return 1;
            }
        })
    }

    getExistIdsSort() {
        this.existIdsSort();
        return this.existIds;
    }
}
let IdGenerator = new IdGeneratorIpm() 

export default IdGenerator