import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import UiUtil from "../../../../utils/UiUtil";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import MindElementType from "../../../../viewmodel/datatype/MindElementType";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndIconCalculation extends BaseMindElementDataCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }


    calculate() {
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();
        let iconAndImageSpace = new UiUtil().dip2px(5);
        let text = "";
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
            text = this.data.textContent.text;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
            text = this.data.generalizationContent.text;
        }

        if (imageContent.layoutType == ImageLayoutType.LAYOUT_LEFT) {
            let iconsRect;
            if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                iconsRect = this.caluleIconElementRect(new UiUtil().dip2px(8), 0);
            } else {
                iconsRect = this.caluleIconElementRect(new UiUtil().dip2px(5), 0);
            }
            if (imageContent.height > textMindElementContent.height) {
                imageContent.y = 0;
                imageContent.x = (iconsRect.width()) + iconAndImageSpace;

                if (text.length == 0 && !this.data.isEdit) {
                    textMindElementContent.x = 0;
                    textMindElementContent.y = 0;
                } else {
                    textMindElementContent.x = imageContent.x + imageContent.width;
                    textMindElementContent.y = (imageContent.height - textMindElementContent.height) / 2;
                }
                if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                    iconsRect = this.caluleIconElementRect(new UiUtil().dip2px(8), 0);
                } else {
                    iconsRect = this.caluleIconElementRect(new UiUtil().dip2px(5), 0);
                }
            } else {
                imageContent.y = (textMindElementContent.height - imageContent.height) / 2;
                imageContent.x = (iconsRect.width()) + iconAndImageSpace;

                if (text.length == 0 && !this.data.isEdit) {
                    textMindElementContent.x = 0;
                    textMindElementContent.y = 0;
                } else {
                    textMindElementContent.x = imageContent.x + imageContent.width;
                    textMindElementContent.y = 0;
                }
                if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                    iconsRect = this.caluleIconElementRect(new UiUtil().dip2px(8), 0);
                } else {
                    iconsRect = this.caluleIconElementRect(new UiUtil().dip2px(5), 0);
                }
            }

            if (text.length == 0 && !this.data.isEdit) {
                this.data.width = (iconsRect.width()) + imageContent.width + iconAndImageSpace;
            } else {
                this.data.width = (iconsRect.width()) + imageContent.width + iconAndImageSpace + textMindElementContent.width;
            }
            if ((imageContent.height > textMindElementContent.height)) {
                this.data.height = imageContent.height;
            } else {
                this.data.height = textMindElementContent.height;
            }
        } else if (imageContent.layoutType == ImageLayoutType.LAYOUT_TOP) {
            let iconsRect;
            if (this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION) {
                iconsRect = this.caluleIconElementRect(new UiUtil().dip2px(8), 0);
            } else {
                iconsRect = this.caluleIconElementRect(new UiUtil().dip2px(5), 0);
            }
            if (imageContent.width > (textMindElementContent.width + (iconsRect.width()))) {
                imageContent.y = 0;
                imageContent.x = 0;
                let iconsLeft = 0;
                if (text.length == 0 && !this.data.isEdit) {
                    textMindElementContent.x = 0;
                    textMindElementContent.y = 0;
                    iconsLeft = (imageContent.width - (iconsRect.width())) / 2;
                } else {
                    iconsLeft = (imageContent.width - textMindElementContent.width - (iconsRect.width())) / 2;
                    textMindElementContent.x = iconsLeft + (iconsRect.width());
                    textMindElementContent.y = imageContent.y + imageContent.height;
                }
                iconsRect = this.caluleIconElementRect(iconsLeft, 0);
                this.data.width = imageContent.width;
            } else {
                imageContent.y = 0;
                imageContent.x = (textMindElementContent.width + (iconsRect.width()) - imageContent.width) / 2;
                let iconsLeft = new UiUtil().dip2px(5);
                if (text.length == 0 && !this.data.isEdit) {
                    textMindElementContent.x = 0;
                    textMindElementContent.y = 0;
                } else {
                    textMindElementContent.x = (iconsRect.width());
                    textMindElementContent.y = imageContent.y + imageContent.height;
                }
                iconsRect = this.caluleIconElementRect(iconsLeft, 0);
                this.data.width = (textMindElementContent.width + (iconsRect.width()));
            }
            this.data.height = imageContent.height + textMindElementContent.height;
            this.setCustomSize(this.data.customWidth);
        }
    }
}
export default TextAndImageAndIconCalculation