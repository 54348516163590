import NetworkEnvironment from "./NetworkEnvironment"

class HttpServerConfigClass {
    constructor() {
        this.NetworkEnvironment = NetworkEnvironment.RELEASE
        this.Channel = 0
        /*  0:  官网渠道包 
            45: "百助CPS",
            46: "百度阿拉丁-云动",
            47: "sem-云动",
            48: "华军软件园",  //www.onlinedown.net
            49: "下载之家",   //www.downza.cn
            50: "PC下载网",  //www.pcsoft.com.cn
            51: "188软件园", //www.188soft.com
            52: "51下载",   //www.51xiazai.cn
            55: "百助SEO安裝數統計"
            56: "云动捆绑静默安装"
            57: "百助捆绑静默安装"
            58: "SEO_58"
            59: "百助下载站"
            60: "百助优易搜"
            61: "云动-Win软件管理"
            62: "腾讯软件中心"
            63: "极速下载平台"
            64："Microsoft Store"
            65："360软件管家"
            66："当快软件园"
            67："华军软件"
            68："联想软件"
            69: "百助小工蚁"
                */
        this.LocalPathPrefix = "xjmm://"
    }
    getServerApi() {
        switch (this.NetworkEnvironment) {
            case NetworkEnvironment.TEST:
                return "https://api-test.mindyushu.com";
            case NetworkEnvironment.RELEASE:
                return "https://api.mindyushu.com";
            case NetworkEnvironment.DEVELOPMENT:
                return "http://192.168.3.4:12300";
            default:
                return "https://api.mindyushu.com";
        }
    }
    getWeb() {
        switch (this.NetworkEnvironment) {
            case NetworkEnvironment.TEST:
                return "https://web-test.mindyushu.com";
            case NetworkEnvironment.RELEASE:
                return "https://web.mindyushu.com";
            case NetworkEnvironment.DEVELOPMENT:
                return "http://192.168.3.4:8080";
            default:
                return "https://web.mindyushu.com";
        }
    }

    getUploadUrl() {
        switch (this.NetworkEnvironment) {
            case NetworkEnvironment.TEST:
                return "https://api-test.mindyushu.com/mindmap/upload-image";
            case NetworkEnvironment.RELEASE:
                return "https://api.mindyushu.com/mindmap/upload-image";
            case NetworkEnvironment.DEVELOPMENT:
                return "http://192.168.3.4:12300/mindmap/upload-image";
            default:
                return "https://api.mindyushu.com/mindmap/upload-image";
        }
    }

    getResourceDownloadRoot() {
        switch (this.NetworkEnvironment) {
            case NetworkEnvironment.TEST:
                return "http://r-test.mindyushu.com/";
            case NetworkEnvironment.RELEASE:
                return "https://r.mindyushu.com/";
            case NetworkEnvironment.DEVELOPMENT:
                return "http://192.168.3.4:12300/";
            default:
                return "https://r.mindyushu.com/";
        }
    }
    getMediaSrc(s) {
        if (!s) {
            return '';
        }
        if (s.startsWith('http')) {
            return s;
        }
        if (s.startsWith('data')) {
            return s;
        }
        return this.getResourceDownloadRoot() + s;
    }

    removeUrlPrefix(s) {
        if (!s) {
            return '';
        }
        if (s.startsWith(this.getResourceDownloadRoot())) {
            let url = s.replace(this.getResourceDownloadRoot(), "")
            return url;
        }
        
        return s;
    }

    getLocalPathPrefix(path, appPath) {
        if (path == null || path.length == 0) {
            return '';
        }
        if (path.startsWith('http://') || path.startsWith('https://')) {
            return path;
        }
        //xjmm://ca1b2c3c4b5a/Users/asd/AppData/Roaming/xjmmrecent/%E5%8F%8C%E5%90%91%E5%AF%BC%E5%9B%BE_xj_prefix_1/content/content.json
        if (appPath != null && appPath.length > 0 && path.startsWith(appPath)) {
            path = path.replace(appPath, "")
        }
        if (path.indexOf(":") > -1) {
            path = path.replace(":", "a1b2c3c4b5a")
        }
        return this.LocalPathPrefix + path;
    }
}

const HttpServerConfig = new HttpServerConfigClass()

export default HttpServerConfig
