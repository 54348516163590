

/**
 * Created by tony on 2019/12/26
 */

let LineLayout = {
    UNKNOWN:0, //未知类型
    STRAIGHT_LINE:1, //直线 1
    CURVE_LINE:2,  //二次d弧度线2
    CURVE_LINE_2:3,  //二次d弧度线3
    RIGHT_ANGLE_LINE:4, //直角5
    RIGHT_ANGLE_CORNER_LINE:5, //直圆角4
    FULL_RIGHT_ANGLE_CORNER_LINE:6, //中心直线6
    RIGHT_ANGLE_CORNER_ARROW_LINE:7, //直圆角箭头7
    FULL_RIGHT_ANGLE_CORNER_ARROW_LINE:8, //中心直线箭头8
    STRAIGHT_LINE_2:9, //直线
    CURVE_LINE_AVERAGE:10,  //起点平分二次d弧度线
    CURVE_LINE_CIRCULAR:11,
    STRAIGHT_CURVE_LINE:12, //直线转角大圆角
    
    // from:function(value) {
    //     for(let mealName of Object.values(this)){
    //         if(mealName == value){
    //             return value;
    //         }
    //     }
    //     return this.STRAIGHT_LINE;
    // }


}

export default LineLayout