import Size from "../../../../viewmodel/core/base/Size";
import TextAndLinkCalculation from "./TextAndLinkCalculation";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */
class TextAndResourceAndTaskCalculation extends TextAndLinkCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (!this.data.isContainResourceContent() || this.data.taskContent == null) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        let resourceContent = this.data.resourceContent;
        if (textMindElementContent == null) {
            return;
        }
        
        let textRext = this.caluleTextCGRect();

        let textHeight = textRext.height();
        resourceContent.width = textHeight;
        resourceContent.height = textHeight;
        let size = this.includeResourceAndTextAndTaskSize();
        let resourceAndTextSize = this.includeResourceAndTextSize();
        let iconsSize = new Size(resourceContent.width, resourceContent.height);
        let textEdgeInsets = this.getTextEdgeInsets();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;

        iconLeft = (resourceAndTextSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (resourceAndTextSize.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((resourceAndTextSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

        resourceContent.x = iconLeft + marginX;
        resourceContent.y = iconTop + marginY;

        this.data.taskContent.x = marginX + textEdgeInsets.left;
        this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}
export default TextAndResourceAndTaskCalculation
