class MindMapZipConstant {
    constructor() {
        this.MindZIPContentFolderName = "content" //存放导图主体json文件夹
        this.MindZIPContentName = "content.json" //存放导图主体json序列文件
        this.MindZIPContentPath = "content/content.json" //存放导图主体json序列文件路径
        this.MindZIPInsideContentPath = "content/content.json" //存放导图主体json序列文件路径
        this.MindZIPContentTemporaryFolderName = "temp" //存放导图主体json文件夹
        this.MindZIPImgFolderName = "img"
        this.MindZIPFilesFolderName = "file"
        this.MindZIPLocalFilesFolderName = "/xjmmrecent"
        this.MindZIPLocalFilesHistoryName = "history.js"
        this.MindZIPLocalFilesPrefixName = "_xj_prefix_"
        this.MindZIPNewPathe = "new_path"
        this.FileSeparator = "/"
        this.setSeparator()
    }

    setSeparator() {
        let platform = navigator.platform.toLowerCase()
        let isWindows = platform.indexOf('win32') > -1 || platform.indexOf('win64') > -1
        if (isWindows) {
            this.FileSeparator = "\\"
            this.MindZIPContentPath = this.MindZIPContentPath.replaceAll("/", this.FileSeparator)
            this.MindZIPLocalFilesFolderName = this.MindZIPLocalFilesFolderName.replaceAll("/", this.FileSeparator)
        } else {
            this.FileSeparator = "/"
        }
    }
}
export default new MindMapZipConstant()