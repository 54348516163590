import Size from "../../../../viewmodel/core/base/Size";

const { default: BaseMindElementDataCalculation } = require("./BaseMindElementDataCalculation");

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndLinkCalculation extends BaseMindElementDataCalculation {
    constructor(data, nodeLayoutType) {
        super(data, nodeLayoutType)
    }

    calculate() {
        if (!this.data.isContainLinkContent()) {
            return;
        }
        let textMindElementContent;
        let linkElementContent = this.data.linkElementContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        } else {
            return;
        }
        let textRext = this.caluleTextCGRect();

        let textHeight = textRext.height();
        linkElementContent.width = textHeight;
        linkElementContent.height = textHeight;
        let size = this.includeLinkAndTextSize();
        let iconsSize = new Size(linkElementContent.width, linkElementContent.height);

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;
        if (!this.data.isContainLinkContent()) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let iconLeft = 0;
        let iconTop = 0;
        iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
        iconTop = (size.getHeight() - iconsSize.getHeight()) / 2;
        textMindElementContent.x = marginX;
        textMindElementContent.y = ((size.getHeight()) - textMindElementContent.height) / 2 + marginY;

        linkElementContent.x = iconLeft + marginX;
        linkElementContent.y = iconTop + marginY;

        // this.setMindElementSize(size.getWidth(), size.getHeight(), marginX, marginY);
        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}
export default TextAndLinkCalculation
