import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import TextAndImageAndRemarksCalculation from "./TextAndImageAndRemarksCalculation";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */
 
class TextAndImageAndRemarksAndTaskCalculation extends TextAndImageAndRemarksCalculation {

    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (this.data.imageContent == null || !this.data.isContainRemarksContent()) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();

        let remarksElementContent = this.data.remarksElementContent;
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        remarksElementContent.width = textHeight;
        remarksElementContent.height = textHeight;

        let textRemarksSize = this.includeRemarksAndTextSize();
        let size = this.includeImageAndTextRemarksAndTaskSize();
        let imageAndTextRemarksSize = this.includeImageAndTextRemarksSize();
        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;
        let remarksTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {
            iconTop = (imageAndTextRemarksSize.getHeight() - iconsSize.getHeight()) / 2;
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconMargin) : 0;
            iconLeft = Math.min(iconTop, iconLeft);
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((imageAndTextRemarksSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            remarksElementContent.x = textMindElementContent.x + textMindElementContent.width + remarksTextSpace;
            remarksElementContent.y = ((imageAndTextRemarksSize.getHeight()) - remarksElementContent.height) / 2;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            iconLeft = (imageAndTextRemarksSize.getWidth() - iconsSize.getWidth() - this.iconMargin);
            iconTop = (imageAndTextRemarksSize.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((imageAndTextRemarksSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            remarksElementContent.x = textMindElementContent.x + textMindElementContent.width + remarksTextSpace;
            remarksElementContent.y = ((imageAndTextRemarksSize.getHeight()) - remarksElementContent.height) / 2;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (imageAndTextRemarksSize.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = iconLeft > (this.iconSpace) ? (this.iconSpace) : iconLeft;
            textMindElementContent.x = ((imageAndTextRemarksSize.getWidth()) - (textRemarksSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            remarksElementContent.x = textMindElementContent.x + textMindElementContent.width + remarksTextSpace;
            remarksElementContent.y = textMindElementContent.y + ((textRemarksSize.getHeight()) - remarksElementContent.height) / 2;
        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        let textEdgeInsets = getTextEdgeInsets();
        this.data.taskContent.x = marginX + textEdgeInsets.left;
        this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }
}
export default TextAndImageAndRemarksAndTaskCalculation