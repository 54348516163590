import MindKeyboard from "./MindKeyboard";

class MindSettingData {
    constructor() {
        this.doubleClickCreateFreeNode = false;
        this.clickImagePreview = false;
        this.freeNodeMoveAdsorbent = false;
        this.mindExportImageSpace = 60;
        this.mindKeyboards = new Array();
    }
    toJSONString() {
        let dataStr = JSON.stringify(this);
        return dataStr;
    }
    format(vaule) {
        let mind = JSON.parse(vaule)
        if (mind != null) {
            let settingData = new MindSettingData();
            for (var key in mind) {
                let keyValue = mind[key]
                settingData[key] = keyValue
            }
            return settingData;
        }
        return new MindSettingData();
    }
}
export default MindSettingData    