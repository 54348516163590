// package mind.yushu.com.mindmap.core.storage.sharedPer;

// import mind.yushu.com.mindmap.core.common.Definition;
// import mind.yushu.com.mindmap.viewmodel.datatype.UpgradeType;

/**
 * ProjectName: MindMap
 * Created by tony on 2020/8/25
 * Copyright(c) 2020 mindyushu.com
 */
class StorageUserDefault {

    saveUsername(name) {

    }


    getUsername() {
        return null;
    }


    saveUpgradeExpireTime(expireTime) {

    }


    getUpgradeExpireTime() {
        return 0;
    }


    saveUpgradeType(type) {

    }


    getUpgradeType() {
        return null;
    }


    saveAppVersion(version) {

    }


    getAppVersion() {
        return null;
    }


    saveIsOpenHelpPanel(value) {

    }


    getIsOpanHelpPanel() {
        return false;
    }


    saveEnterMindMapTimes() {

    }


    getEnterMindMapTimes() {
        return 0;
    }


    saveDisplayAppCommentTime(expireTime) {

    }


    getDisplayAppCommentTime() {
        return 0;
    }


    saveDisplayAppCommentStatus(status) {

    }


    getDisplayAppCommentStatus() {
        return 0;
    }


    saveAgreePrivacy() {

    }


    getIsAgreePrivacy() {
        return false;
    }


    saveClickPreviewImage(value) {

    }


    getClickPreviewImage() {
        return false;
    }


    saveHistoryColors(value) {
        UserDefaultUtils.saveString(Definition.USER_DEFAULT_HISTORY_COLOR_KEY, value);
    }


    getHistoryColors() {
        return UserDefaultUtils.loadString(Definition.USER_DEFAULT_HISTORY_COLOR_KEY);
    }


    saveConnectLineDragStatus() {

    }


    getConnectLineDragStatus() {
        return false;
    }


    saveDevideUUid(value) {
        UserDefaultUtils.saveString(Definition.USER_DEFAULT_DEVIDE_UUID_KEY, value);
    }


    getDevideUUid() {
        return UserDefaultUtils.loadString(Definition.USER_DEFAULT_DEVIDE_UUID_KEY);
    }


    saveCheckUpdataTime(value) {
        UserDefaultUtils.saveString(Definition.USER_DEFAULT_CHECK_UPDATA_TIME_KEY, value);
    }


    getCheckUpdataTime() {
        return UserDefaultUtils.loadString(Definition.USER_DEFAULT_CHECK_UPDATA_TIME_KEY);
    }
}
export default StorageUserDefault