// import mind.yushu.com.mindmap.core.basemode.User;
// import mind.yushu.com.mindmap.core.storage.sharedPer.Storageerface;
// import mind.yushu.com.mindmap.core.storage.sharedPer.StorageUserDefault;
// import mind.yushu.com.mindmap.utils.Logger;
// import mind.yushu.com.mindmap.utils.Strings;
// import mind.yushu.com.mindmap.utils.Util;
// import mind.yushu.com.mindmap.viewmodel.core.minddata.CustomStyleDocument;
// import mind.yushu.com.mindmap.viewmodel.core.minddata.DeletedMapDocument;
// import mind.yushu.com.mindmap.viewmodel.core.minddata.IcloudIdDescribe;
// import mind.yushu.com.mindmap.viewmodel.core.minddata.MindDescribe;
// import mind.yushu.com.mindmap.viewmodel.core.minddata.MindFileDescribe;
// import mind.yushu.com.mindmap.viewmodel.core.minddata.UseHistoryCell;
// import mind.yushu.com.mindmap.viewmodel.datatype.AppCommentStatus;
// import mind.yushu.com.mindmap.viewmodel.datatype.UpgradeType;

import User from "../../core/basemode/User"
import StorageUserDefault from "../../core/storage/sharedPer/StorageUserDefault"
import Util from "../../../utils/Util"
import CustomStyleDocument from "../../../viewmodel/core/minddata/CustomStyleDocument"
import DeletedMapDocument from "../../../viewmodel/core/minddata/DeletedMapDocument"
import IcloudIdDescribe from "../../../viewmodel/core/minddata/IcloudIdDescribe"
import MindDescribe from "../../../viewmodel/core/minddata/MindDescribe"
import MindFileDescribe from "../../../viewmodel/core/minddata/MindFileDescribe"
import UseHistoryCell from "../../../viewmodel/core/minddata/UseHistoryCell"
import AppCommentStatus from "../../../viewmodel/datatype/AppCommentStatus"
// import UpgradeType from "../../../viewmodel/datatype/UpgradeType"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/28
 * Copyright(c) 2020 mindyushu.com
 */

class StorageManager {
    constructor() {
        this.sharedInstance = null;
        this.sqlite;
        this.storageHolder;
        this.expireTime = -1;
        this.isLoadUpgradeExpireTime = false;
        this.isFirstOpenApp = false;
    }
    get() {
        if (this.sharedInstance == null) {
            this.sharedInstance = new StorageManager();
            this.sharedInstance.registerStorage(new StorageUserDefault());
        }
        return this.sharedInstance;
    }
    registerStorage(store) {
        this.storageHolder = store;
    }

    saveLogin() {

    }

    isLogin() {
        return false;
    }

    getMindAndIcloud() {
        return new Array();
    }

    createDeletedMap(name, imageUrl, subtitle, mindType, nodes) {
        return 1;
    }

    getIsAgreePrivacy() {
        return 1;
        // return this.storageHolder.getIsAgreePrivacy();
    }

    saveUsername(name) {
        //        Me.shared.nickname = name
        // this.storageHolder.saveUsername(name);
    }

    getUsername() {
        return 1;
        //return this.storageHolder.getUsername();
    }

    saveAppVersion(version) {
    }

    getAppVersion() {
        return 1;
        //return this.storageHolder.getAppVersion();
    }

    createMindPreview(name, imageUrl, detailsId, mindType) {
        return 1;
        //return this.sqlite.createMyMind(name, imageUrl, detailsId, mindType);
    }

    getMyMindList() {
        return 1;
        //return this.sqlite.getMyMindList();
    }

    saveMyMindImage(id, imageUrl) {
        //this.sqlite.saveMyMindImage(id, imageUrl);
    }

    saveMyMindName(id, name) {
        //this.sqlite.saveMyMindName(id, name);
    }

    saveMyMindSubtitle(id, subtitle) {
        //  this.sqlite.saveMyMindSubtitle(id, subtitle);
    }

    removeMind(id) {
        // this.sqlite.removeMind(id);
    }

    createMindDetails(name) {
        return -1
        //return this.sqlite.createMindDetails(name);
    }

    saveMindNodes(id, nodes) {
        // this.sqlite.saveMindNodes(id, nodes);
    }

    saveMindDetailsLayout(id, layout) {
        // this.sqlite.saveMindDetailsLayout(id, layout);
    }

    getMindDetails(id) {
        return 1;
        // return this.sqlite.getMindDetails(id);
    }

    getPreviewMindById(id) {
        return 1;
        // return this.sqlite.imageurl;
        // return this.sqlite.readMindDescribeById(id);
    }

    saveUpgradeExpireTime(expireTime) {
        // if (expireTime != this.expireTime):function() {
        //     this.expireTime = expireTime;
        //     this.storageHolder.saveUpgradeExpireTime(expireTime);
        // }
    }

    getUpgradeExpireTime() {
        //        let now = Date()
        //        var timeerval:Timeerval = now.timeIntervalSince1970
        //        timeInterval = now.timeIntervalSince1970 + 31 * 24 * 60 * 60
        //
        //        return Int(timeInterval)
        return -1;//this.storageHolder.getUpgradeExpireTime();
    }

    saveUpgradeType(type) {
        // this.storageHolder.saveUpgradeType(type);
    }

    getUpgradeType() {
        return 1;
        // return this.storageHolder.getUpgradeType();
    }

    getAllImageKey() {
        let imageKeys = new Array();

        let list = this.getMyMindList();
        list.forEach(item => {
            imageKeys.push(item.imageurl);
        });
        return 1;
        // return this.imageKeys;
    }

    saveIsOpenHelpPanel(value) {
        // this.storageHolder.saveIsOpenHelpPanel(value);
    }

    getIsOpanHelpPanel() {
        return 1;
        // return this.storageHolder.getIsOpanHelpPanel();
    }

    createMindFile(name, parentId, type) {
        return 1;
        // return this.sqlite.createMindFile(name, parentId, type);
    }

    saveMindFileName(id, name) {
        // this.sqlite.saveMindFileName(id, name);
    }

    saveMindFileChilds(id, childs) {
        // this.sqlite.saveMindFileChilds(id, childs);
    }

    saveMindFileParent(id, parentId) {
        // this.sqlite.saveMindFileParent(id, parentId);
    }

    saveMindFileType(id, type) {
        // this.sqlite.saveMindFileType(id, type);
    }

    readMindFileDescribeById(id) {
        return 1;
        // return this.sqlite.readMindFileDescribeById(id);
    }

    getMindFilelist() {
        return 1;
        // return this.sqlite.getMindFilelist();
    }

    removeMindFile(id) {
        // this.sqlite.removeMindFile(id);
    }

    createIcloud(mindId, icloudId) {
        return 1;
        // return this.sqlite.createIcloud(mindId, icloudId);
    }

    remove(id) {
        // this.sqlite.remove(id);
    }

    getIcloudIdlist() {
        return 1;
        // return new Array();//this.sqlite.getIcloudIdlist()
    }

    saveEnterMindMapTimes() {
        // this.storageHolder.saveEnterMindMapTimes();
    }

    getEnterMindMapTimes() {
        return 1;
        // return this.storageHolder.getEnterMindMapTimes();
    }

    saveDisplayAppCommentTime(expireTime) {
        // this.storageHolder.saveDisplayAppCommentTime(expireTime);
    }

    getDisplayAppCommentTime() {
        return 1;
        // return this.storageHolder.getDisplayAppCommentTime();
    }

    saveDisplayAppCommentStatus(status) {
        // this.storageHolder.saveDisplayAppCommentStatus(status.getValue());
    }

    getDisplayAppCommentStatus() {
        // let status = this.storageHolder.getDisplayAppCommentStatus();
        return 1;
        // return AppCommentStatus.from(this.status);
    }

    saveClickPreviewImage(value) {
        // this.storageHolder.saveClickPreviewImage(value);
    }

    getClickPreviewImage() {
        return 1;
        // return this.storageHolder.getClickPreviewImage();
    }

    createDeletedMap(name, imageUrl, subtitle, mindType, nodes) {
        return 1;
        // return this.sqlite.createDeletedMap(name, imageUrl, subtitle, mindType, nodes);
    }

    removeDeletedMap(id) {
        // this.sqlite.removeDeletedMap(id);
    }

    getDeletedMapList() {
        return 1;
        // return this.sqlite.getDeletedMapList();
    }

    getDeletedMapById(id) {
        return 1;
        // return this.sqlite.getDeletedMapById(id);
    }

    createCustomStyle(imageUrl, nodes) {
        return 1;
        // return this.sqlite.createCustomStyle(imageUrl, nodes);
    }

    removeCustomStyle(id) {
        // this.sqlite.removeCustomStyle(id);
    }

    getCustomStyleList() {
        return 1;
        // return this.sqlite.getCustomStyleList();
    }

    getCustomStyleNodesById(id) {
        return 1;
        // return this.sqlite.getCustomStyleNodesById(id);
    }

    saveHistoryColors(value) {
        // this.storageHolder.saveHistoryColors(value);
    }

    getHistoryColors() {
        return 1;
        // return this.storageHolder.getHistoryColors();
    }

    saveConnectLineDragStatus() {
        // this.storageHolder.saveConnectLineDragStatus();
    }

    getConnectLineDragStatus() {
        return 1;
        // return this.storageHolder.getConnectLineDragStatus();
    }

    saveInstallTime() {
        // this.sqlite.saveInstallTime();
    }

    getInstallTime() {
        return 1;
        // return this.sqlite.getInstallTime();
    }

    saveDevideUUid(value) {
        // this.storageHolder.saveDevideUUid(value);
    }

    getDevideUUid() {
        return 1;
        // return this.storageHolder.getDevideUUid();
    }

    getUser() {
        return 1;
        // return this.sqlite.getUser();
    }

    creatUser(userId, name, creatTime, memeberType,
        memberExpire, userLevel, authorization) {
        // this.sqlite.creatUser(userId, name, creatTime, memeberType, memberExpire, userLevel, authorization);
    }

    saveUserName(name) {
        // this.sqlite.saveUserName(name);
    }

    saveMemberExpire(memberExpire) {
        // this.sqlite.saveMemberExpire(memberExpire);
    }

    saveMemberType(memeberType) {
        // this.sqlite.saveMemberType(memeberType);
    }

    saveUserLevel(userLevel) {
        // this.sqlite.saveUserLevel(userLevel);
    }

    saveAuthorization(authorization) {
        // this.sqlite.saveAuthorization(authorization);
    }

    saveServerLocalTimeDifference(time) {
        // this.sqlite.saveServerLocalTimeDifference(time);
    }

    getServerLocalTimeDifference() {
        return 1;
        // return this.sqlite.getServerLocalTimeDifference();
    }

    savePayPrice(value) {
        // this.sqlite.savePayPrice(value);
    }

    getPayPrice() {
        return 1;
        // return this.sqlite.getPayPrice();
    }

    savePayChannels(value) {
        // this.sqlite.savePayChannels(value);
    }

    getPayChannels() {
        return 1;
        // return this.sqlite.getPayChannels();
    }

    saveCheckUpdataTime(value) {
        // this.storageHolder.saveCheckUpdataTime(value + "");
    }

    getCheckUpdataTime() {
        let valueStr = this.storageHolder.getCheckUpdataTime();
        try {
            if (valueStr.length == "0") {
                return -1;
            } else {
                return parseInt(this.valueStr);
            }
        } catch (e) {
            console.log(e);
        }
        return -1;
    }
}
export default StorageManager
