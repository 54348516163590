// package mind.yushu.com.mindmap.viewmodel.mindelementdata;

// import java.util.ArrayList;
// import java.util.List;

// import mind.yushu.com.mindmap.core.calcule.Config;
// import mind.yushu.com.mindmap.core.calcule.elementCalculation.MindElementCalculation;
// import mind.yushu.com.mindmap.utils.Colors;
// import mind.yushu.com.mindmap.viewmodel.core.base.IdGenerator;
// import mind.yushu.com.mindmap.viewmodel.core.base.LineColorMode;
// import mind.yushu.com.mindmap.viewmodel.datatype.MindElementType;

import Config from "../../core/core/calcule/Config"
import MindElementCalculation from "../../core/core/calcule/elementCalculation/MindElementCalculation"
import Colors from "../../utils/Colors"
import IdGenerator from "../core/base/IdGenerator"
import LineColorMode from "../core/base/LineColorMode"
import MindElementType from "../datatype/MindElementType"

import MindElementData from "../../viewmodel/mindelementdata/MindElementData"
/**
 * Created by tony on 2019/12/26
 */

class LineMindTypeNode {

    constructor(data) {
        if (arguments.length == 0 || data == null || data.id == undefined || data.id == null) {
            this.value =  new MindElementData().emptyMindNode();
        } else {
            this.value = data;
        }
        
        this.children = new Array();
    }

    isChildrenEmpty() {
        return this.children == null || this.children.length == 0;
    }

    emptyMindNode() {
        // let node = new LineMindTypeNode();
        // node.value = new MindElementData();
        return new LineMindTypeNode();
    }

    createMindNode(value) {
        let node = new LineMindTypeNode();
        node.value = value;
        return node;
    }

    isEmpty() {
        return this.value.id == IdGenerator.INVALID_ID;
    }

    addChildrenNode(value, at) {
        if (arguments.length == 1) {
            value.value.parentNodeId = this.value.id;
            if (this.value.type == MindElementType.MAIN_SUBJECT) {
                this.changeDataType(value.value, MindElementType.SUBJECT);
                value.children.forEach(cell => {
                    this.changeDataType(cell.value, MindElementType.SON_SUBJECT);
                    cell.children.forEach(cell1 => {
                        this.changeDataType(cell1.value, MindElementType.SON_SUBJECT);
                    });
                });
            } else if (this.value.type == MindElementType.SUBJECT || this.value.type == MindElementType.CONTENT_GENERALIZATION) {
                this.changeDataType(value.value, MindElementType.SON_SUBJECT);
                value.children.forEach(cell => {
                    this.changeDataType(cell.value, MindElementType.SON_SUBJECT);
                    cell.children.forEach(cell1 => {
                        this.changeDataType(cell1.value, MindElementType.SON_SUBJECT);
                    });
                });
            } else if (this.value.type == MindElementType.SON_SUBJECT) {
                this.changeDataType(value.value, MindElementType.SON_SUBJECT);
                value.children.forEach(cell => {
                    this.changeDataType(cell.value, MindElementType.SON_SUBJECT);
                    cell.children.forEach(cell1 => {
                        this.changeDataType(cell1.value, MindElementType.SON_SUBJECT);
                    });
                });
            }
            this.children.push(value);
        } else if (arguments.length == 2) {
            this.children.splice(at, 0, value);
        }
    }

    getChildIndex(value) {
        if (this.children.length == 0) {
            return -1;
        }
        let length = this.children.length
        for (var index = 0; index < length; index++) {
            if (value.value.id == this.children[index].value.id) {
                return index;
            }
        }
        return -1;
    }

    removeChildrenNode(value) {
        if (this.children.length == 0) {
            return;
        }
        let length = this.children.length
        for (var index = 0; index < length; index++) {
            let cell = this.children[index];
            if (cell.value.id == value.value.id) {
                this.children.splice(index, 1)
                return;
            }
        }
    }

    outlineCalculationText() {
        this.outlineCalculationText(this);
    }

    outlineCalculationText(node) {
        //        new OutlineMindElementCalculation(data:node.value).caluleText()
        //        for item in node.children {
        //            outlineCalculationText(node: item)
        //        }
    }

    getNodeTop() {
        return this.getNodeTopAccept(this);
    }

    getNodeBottom(absolute) {
        return this.getNodeBottomByAccept(this, arguments.length == 0 ? false : absolute);
    }

    getOutlineNodeBottom(node, absolute) {
        if (arguments.length == 0) {
            node = this;
            absolute = false;
        } else if (arguments.length == 1) {
            node = this;
            absolute = absolute;
        }
        if (!absolute && node.value.isHidden) {
            return 0;
        }
        var bottom = node.value.outlineY + node.value.height;
        node.children.forEach(item => {
            var itemBottom = this.getOutlineNodeBottom(item, absolute);
            if (itemBottom > bottom) {
                bottom = itemBottom;
            }
        });
        return bottom;
    }

    getNodeTopAccept(node) {
        if (node.value.isHidden) {
            return Config.Mind_Height;
        }
        var top = node.value.y;
        node.children.forEach(item => {
            var itemTop = this.getNodeTopAccept(item);
            if (itemTop < top) {
                top = itemTop;
            }
        });
        return top;
    }

    resetLevel(node, level) {
        if (arguments.length == 1) {
            if ((typeof node) == "number") {
                this.resetLevel(this, node);
            }
        } else if (arguments.length == 2) {
            if (node == null ||
                node)
            node.value.level = level;
            node.children.forEach(item => {
                this.resetLevel(item, level + 1);
            });
        }

    }

    getNodeRight() {
        return this.getNodeRightByTake(this);
    }

    getNodeLeft() {
        return this.getNodeLeftByAccept(this);
    }

    getNodeLeftByAccept(node) {
        if (node.value.isHidden) {
            return Config.Mind_Width;
        }
        var left = node.value.x;
        node.children.forEach(item => {
            var itemLeft = this.getNodeLeftByAccept(item);
            if (itemLeft < left) {
                left = itemLeft;
            }
        });
        return left;
    }

    getNodeBottomByAccept(node, absolute) {
        if (!absolute && node.value.isHidden) {
            return 0;
        }
        var bottom = node.value.y + node.value.height;
        node.children.forEach(item => {
            var itemBottom = this.getNodeBottomByAccept(item, absolute);
            if (itemBottom > bottom) {
                bottom = itemBottom;
            }
        });
        return bottom;
    }

    getNodeRightByTake(node) {
        if (node.value.isHidden) {
            return 0;
        }
        var right = node.value.x + node.value.width;
        node.children.forEach(item => {
            var itemRight = this.getNodeRightByTake(item);
            if (itemRight > right) {
                right = itemRight;
            }
        });
        return right;
    }

    copy(setId = false) {
        var node = new LineMindTypeNode();
        node.value = this.value.copy();
        if (setId) {
            node.value.id = IdGenerator.shared.getId();
        }
        var childrenCount = this.children.length;
        if (childrenCount > 0) {
            for (var index = 0; index < childrenCount; index++) {
                var newNode = this.children[index].copy(setId);
                if (setId) {
                    newNode.value.parentNodeId = node.value.id;
                }
                node.children.push(newNode);
            }
        }
        return node;
    }

    stickStyleByNode(node) {
        if (node.isEmpty()) {
            return;
        }
        this.value.stickStyle(node.value);
    }

    stickStyle(data) {
        if (data.isEmpty()) {
            return;
        }
        if (data.value != null && data.children != null) {
            this.value.stickStyle(data.value);
        } else {
            this.value.stickStyle(data);
        }
        
    }

    stickColorStyle(data) {
        if (data.isEmpty()) {
            return;
        }
        this.value.stickColorStyle(data);
    }

    stickNode(node, index) {
        if (node.isEmpty()) {
            return node;
        }
        if (arguments.length < 2) {
            index = -1
        }
        var oldNodeParentId = node.value.parentNodeId;
        var newNode = node.copy(true);
        newNode.value.parentNodeId = this.value.id;
        if (this.value.type == MindElementType.MAIN_SUBJECT) {
            this.changeDataType(newNode.value, MindElementType.SUBJECT);
            newNode.children.forEach(cell => {
                this.changeDataType(cell.value, MindElementType.SON_SUBJECT);
                cell.children.forEach(cell1 => {
                    this.changeDataType(cell1.value, MindElementType.SON_SUBJECT);
                });
            });
        } else if (this.value.type == MindElementType.SUBJECT || this.value.type == MindElementType.CONTENT_GENERALIZATION) {
            this.changeDataType(newNode.value, MindElementType.SON_SUBJECT);
            newNode.children.forEach(cell => {
                this.changeDataType(cell.value, MindElementType.SON_SUBJECT);
                cell.children.forEach(cell1 => {
                    this.changeDataType(cell1.value, MindElementType.SON_SUBJECT);
                });
            });
        } else if (this.value.type == MindElementType.SON_SUBJECT) {
            this.changeDataType(newNode.value, MindElementType.SON_SUBJECT);
            newNode.children.forEach(cell => {
                this.changeDataType(cell.value, MindElementType.SON_SUBJECT);
                cell.children.forEach(cell1 => {
                    this.changeDataType(cell1.value, MindElementType.SON_SUBJECT);
                });
            });
        }
        if (this.children.length > 0) {
            let length = this.children.length;
            if (index > -1 && index < length) {
                this.addChildrenNode(newNode, index);
            } else {
                // var oldNodeParentIdIndex = -1;
                // for (var index = 0; index < length; index++) {
                //     if (this.children[index].value.id == oldNodeParentId) {
                //         oldNodeParentIdIndex = index;
                //     }
                // }
                // if (oldNodeParentIdIndex > -1 && oldNodeParentIdIndex < length - 1) {
                //     this.addChildrenNode(newNode, oldNodeParentIdIndex + 1);
                // } else {
                    this.children.push(newNode);
                // }
            }
        } else {
            this.children.push(newNode);
        }
        this.resetLevel(this.value.level);
        return newNode;
    }

    changeDataType(data, type) {
        if (data.type != type) {
            // if (this.children.length > 0) {
            //     data.copyStyle(this.children[0].value);
            // } else {
                if (data.type == MindElementType.SUBJECT && type == MindElementType.SON_SUBJECT) {
                    // data.borderColor = Colors.clear;
                    // data.backgroundColor = Colors.clear;
                } else if (data.type == MindElementType.SON_SUBJECT && type == MindElementType.SUBJECT) {
                    if (data.textContent != null && Colors.isClear(data.backgroundColor)) {
                        if (Colors.isDarkColor(data.textContent.textColor)) {
                            data.backgroundColor = 0xffffff;
                        } else {
                            data.backgroundColor = 0x333333;
                        }
                    }
                }
            // }
            data.type = type;
            MindElementCalculation.set(data).caluleTextForData();
        }
    }

    containsData(id) {
        if (this.value.id == id) {
            return true;
        }
        let length = this.children.length;
        for (var index = 0; index < length; index++) {
            let cell = this.children[index];
            if (cell.containsData(id)) {
                return true;
            }
        }
        return false;
    }

    getBottomNode() {
        return getBottomNode(this);
    }

    getBottomNode(node) {
        if (node.children.length == 0) {
            return node;
        }
        return getBottomNode(node.children[node.children.length - 1]);
    }
    
    getString(headEmpty) {
        let string = "";
        if (arguments.length == 0) {
            return this.getString("")
        }
        if (this.value.textContent != null) {
            string = headEmpty + this.value.textContent.text;
        } else if (this.value.generalizationContent != null) {
            string = headEmpty + this.value.generalizationContent.text;
        }
        for (let index = 0; index < this.children.length; index++) {
            const item = this.children[index];
            let itemString = item.getString(headEmpty + "  ");
            string += ("\n" + itemString);
        }
        return string;
    }

    isChildNode(node) {
        if (node == null || node.isEmpty() || this.children.length == 0) {
            return false;
        }
        
        return this.isChildNodeById(node.value.id);
    }

    isChildNodeById(nodeId) {
        if (nodeId == null || nodeId < 0) {
            return false;
        }
        for (let index = 0; index < this.children.length; index++) {
            const cell = this.children[index];
            if (cell.value.id == nodeId) {
                return true;
            }
            if (cell.isChildNodeById(nodeId)) {
                return true;
            }
        }
        return false;
    }

    getAllChild() {
        let list = []
        for (let index = 0; index < this.children.length; index++) {
            const item = this.children[index];
            list.push(item)
            const itemChilds = item.getAllChild()
            list = list.concat(itemChilds)
        }
        return list 
    }
}

export default LineMindTypeNode