// package mind.yushu.com.mindmap.viewmodel.datatype;

// import com.google.gson.annotations.SerializedName;

/**
 * Created by tony on 2020/5/27
 */

let ImageLayoutType = {
    LAYOUT_RIGHT:1,
    LAYOUT_BOTTOM:2,
    LAYOUT_LEFT:3,
    LAYOUT_TOP:4,

    from:function(value) {
        for(let mealName of Object.values(this)){
            if(mealName == value){
                return value;
            }
        }
        return this.LAYOUT_RIGHT;
    }
}

export default ImageLayoutType