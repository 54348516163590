const MindElementFullType = {
    Unknown: -1,       //未定义
    FULL: 0   ,        //填充
    DrawOblique: 1,    //倾斜45度手绘
    DrawHorizontal: 2,    //水平手绘
    DrawGrid: 3,    //手绘网格
    Grid: 4,    //网格
    Gradient: 5,    //渐变色
    GradientLR: 6,    //渐变色
}
export default MindElementFullType