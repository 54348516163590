import MindElementContent from './MindElementContent.js';

// import mind.yushu.com.mindmap.utils.Colors;
import Colors from '../../../utils/Colors'
// import mind.yushu.com.mindmap.viewmodel.datatype.IconLayoutType;
import IconLayoutType from '../../datatype/IconLayoutType'
/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class IconElementContent extends MindElementContent {
    constructor(key = "",width,height,styleColor = Colors.white,tintColor = Colors.clear){
        super(width,height);
        this.key = key;
        this.styleColor = styleColor;
        this.tintColor = tintColor;
        this.layoutType = IconLayoutType.LAYOUT_LEFT;
    }


    // public IconElementContent(String key, int width, int height) {
    //     this(key, width, height, Colors.white, Colors.clear);
    // }

    setIconElementContentData(width, height, styleColor, tintColor,key) {
        this.width = width;
        this.height = height;
        if(styleColor != undefined){
            this.styleColor = styleColor;
        }
        if(tintColor != undefined){
            this.tintColor = tintColor;
        }
        if(key != undefined){
            this.key = key;
        }
    }



    copy() {
        let data = new IconElementContent(this.key, this.width, this.height, this.styleColor, this.tintColor);
        data.x = this.x;
        data.y = this.y;
        data.layoutType = this.layoutType;
        return data;
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.key != this.key;
        result = result || data.styleColor != this.styleColor;
        result = result || data.tintColor != this.tintColor;
        result = result || data.layoutType != this.layoutType;
        return !result;
    }

    stickStyle(data) {
        if (data == null) {
            return;
        }
        this.width = data.width;
        this.height = data.height;
        this.layoutType = data.layoutType;
    }

}

export default IconElementContent
