
import Config from "../../../core/core/calcule/Config"
import MindElementCalculation from "../../../core/core/calcule/elementCalculation/MindElementCalculation"
import Colors from "../../../utils/Colors"
import UiUtil from "../../../utils/UiUtil"
import LineColorMode from "../base/LineColorMode"
import TimeLineNodeType from "../../datatype/TimeLineNodeType"
import TimeDotElementContent from "../../mindelementdata/mindcontent/TimeDotElementContent"
import HashMap from "../base/HashMap"

import BaseLayout from "./BaseLayout"
import TimeNodeLayoutType from "../../datatype/TimeNodeLayoutType";


import MindMapStyleColor from "../base/MindMapStyleColor";
import SettingData from "../minddata/SettingData"
import MindElementType from "../../datatype/MindElementType"
/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/4
 * Copyright(c) 2020 mindyushu.com
 */

class TimeLineLayout {

    constructor() {
        this.delegate;
        this.UiUtil = new UiUtil();
        this.space = this.UiUtil.dip2px(3);
        this.baseLineTitleSpace = this.UiUtil.dip2px(10);
        this.firstSubjectBaseLineTopSpace = this.UiUtil.dip2px(8);
        this.subjectSpace = this.UiUtil.dip2px(8);
        this.titleDescSpace = -this.UiUtil.dip2px(2);
        this.titleDescMaxSpace = this.UiUtil.dip2px(4);

        this.mindMapStyleColor = new MindMapStyleColor(3);
        this.mindBGColor = -1;
        this.radius = this.UiUtil.dip2px(6);
        this.baseLineWidth = this.UiUtil.dip2px(2);

        this.title; //顶部title
        this.baseLine; //基础线
        this.mainMindElementDataDict = new HashMap();
        this.lineColorMode = new LineColorMode();
        this.timeNodeLayoutType = TimeNodeLayoutType.NORMAL;
        this.settingData = new SettingData()
        this.BaseLayout = new BaseLayout();
        this.subjectAndLineSpace = 0;
        this.sonSubjectAndLineSpace = 0;
    
    }

    setDatas(title, baseLine, mainMindElementDataDict, mindMapStyleColor, mindBGColor, settingData) {
        this.title = title;
        this.baseLine = baseLine;
        this.mainMindElementDataDict = mainMindElementDataDict;
        this.mindMapStyleColor = mindMapStyleColor;
        this.mindBGColor = mindBGColor;
        this.settingData = settingData;
        if (baseLine != null && baseLine.timeLineContent != null) {
            this.timeNodeLayoutType = baseLine.timeLineContent.timeNodeLayoutType;
        }
    }

    layout(isChange) {
        this.calculeSizeAndPoint();
        this.onLayout(isChange);
        if (this.delegate != null) {
            this.delegate.callbackResetDatas(this.title, this.baseLine, this.mainMindElementDataDict);
        }
    }

    onLayout(isChange) {
        this.setElementsPoint(isChange);
        this.setElementSytle();
    }

    calculeSizeAndPoint() {
        MindElementCalculation.set(this.title).calcule();
        let mainMindElementDataDictArray = this.mainMindElementDataDict.keys();
        let mainMindElementDataDictLength = mainMindElementDataDictArray.length

        if (this.settingData.sameLevelAlignment) {
            var maxHeadWidth = 0;
            var maxTitleWidth = 0;
            var maxDescWidth = 0;
            for (let index = 0; index < mainMindElementDataDictLength; index++) {
                let key = mainMindElementDataDictArray[index];
                let node = this.mainMindElementDataDict.get(key);
                MindElementCalculation.set(node.head).calcule();
                MindElementCalculation.set(node.title).calcule();
                MindElementCalculation.set(node.desc).calcule();
                maxHeadWidth = Math.max(node.head.width, maxHeadWidth);
                maxTitleWidth = Math.max(node.title.width, maxTitleWidth);
                maxDescWidth = Math.max(node.desc.width, maxDescWidth);
            }

            for (let index = 0; index < mainMindElementDataDictLength; index++) {
                let key = mainMindElementDataDictArray[index];
                let node = this.mainMindElementDataDict.get(key);
                MindElementCalculation.set(node.head).setCustomSize(maxHeadWidth);
                MindElementCalculation.set(node.title).setCustomSize(maxTitleWidth);
                MindElementCalculation.set(node.desc).setCustomSize(maxDescWidth);
            }

        } else {
            for (let index = 0; index < mainMindElementDataDictLength; index++) {
                let key = mainMindElementDataDictArray[index];
                let node = this.mainMindElementDataDict.get(key);
                MindElementCalculation.set(node.head).calcule();
                MindElementCalculation.set(node.title).calcule();
                MindElementCalculation.set(node.desc).calcule();
            }
        }
    }

    resetNodeSize() {
        let mainMindElementDataDictArray = this.mainMindElementDataDict.keys();
        let mainMindElementDataDictLength = mainMindElementDataDictArray.length
        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let key = mainMindElementDataDictArray[index];
            let node = this.mainMindElementDataDict.get(key);

            let maxWidth = this.getHeadMaxSize();
            this.setHeadNodeWidth(node.head, maxWidth - node.head.width);
        }
    }


    initConfigInfo() {
        this.radius = this.getRadius();
        this.baseLineWidth = this.radius * 2 + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth) * 2;
        this.baseLineTitleSpace = this.UiUtil.dip2px(10) + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth);

        if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD ||
            this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_TITLE) {
            this.baseLineWidth += this.UiUtil.dip2px(15);
        } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_ARROW_HEAD) {
            this.baseLineWidth += this.UiUtil.dip2px(17);
        } else {
            this.baseLineWidth += this.UiUtil.dip2px(10);
        }
        this.baseLineWidth = this.addSubjectSpcaeHorizontal(this.baseLineWidth);
        this.space = (this.baseLineWidth - this.radius) / 2;

        this.subjectAndLineSpace = 0;//addSubjectSpcaeHorizontal(0);
        this.sonSubjectAndLineSpace = this.addSonSubjectSpcaeVertical(0);

        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeVertical(this.UiUtil.dip2px(10));
                this.firstSubjectBaseLineTopSpace = this.baseLine.timeLineContent.lineThicken ? this.UiUtil.dip2px(30) : this.UiUtil.dip2px(8);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                this.subjectSpace = this.addSubjectSpcaeVertical(this.UiUtil.dip2px(30));
                this.firstSubjectBaseLineTopSpace = this.baseLine.timeLineContent.lineThicken ? this.UiUtil.dip2px(40) : this.UiUtil.dip2px(20);
                break;
            default:
        }

    }

    setElementsPoint(isChange) {
        if (!isChange && this.title.x < 0) {
            let left = (Config.Mind_Width - this.UiUtil.getScreenWidth()) / 2;
            let top = (Config.Mind_Height - this.UiUtil.getScreenHeight()) / 2;
            this.title.y = (top) + this.BaseLayout.getNodeHeight(this.title) + 20;
            this.title.x = (this.UiUtil.getScreenWidth() - (this.title.width)) / 2 + left;
        }
        this.initConfigInfo();
        this.setTimeLinePoint();
        let top = this.title.y + this.BaseLayout.getNodeHeight(this.title) + this.baseLineTitleSpace + this.firstSubjectBaseLineTopSpace;

        let nodeList = this.getOrderNodes();
        let nodeListLength = nodeList.length;

        for (let index = 0; index < nodeListLength; index++) {
            let timeNode = nodeList[index];
            let head = timeNode.head;
            let title = timeNode.title;
            let desc = timeNode.desc;

            switch (this.timeNodeLayoutType) {
                case TimeNodeLayoutType.NORMAL:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = false;
                    head.x = this.baseLine.x - head.width - this.subjectAndLineSpace;
                    head.y = top;
                    title.x = this.baseLine.x + this.baseLineWidth + this.subjectAndLineSpace;
                    if (head.y + (head.height - title.height) / 2 < top) {
                        title.y = top;
                    } else {
                        title.y = head.y + (head.height - title.height) / 2;
                    }

                    break;
                case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                    head.isHidden = true;
                    title.isHidden = false;
                    desc.isHidden = false;
                    title.x = this.baseLine.x + this.baseLineWidth + this.subjectAndLineSpace;
                    title.y = top;
                    break;
                case TimeNodeLayoutType.TITLE_BOTTOM:
                    head.isHidden = true;
                    title.isHidden = false;
                    desc.isHidden = true;
                    title.x = this.baseLine.x + this.baseLineWidth + this.subjectAndLineSpace;
                    title.y = top;
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = true;
                    head.x = this.baseLine.x - head.width;
                    head.y = top;
                    title.x = this.baseLine.x + this.baseLineWidth - this.subjectAndLineSpace;
                    if (head.y + (head.height - title.height) / 2 < top) {
                        title.y = top;
                    } else {
                        title.y = head.y + (head.height - title.height) / 2;
                    }
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = false;
                    head.x = this.baseLine.x + (this.baseLineWidth - head.width) / 2;
                    head.y = top;
                    title.x = head.x + head.width + this.space + this.subjectAndLineSpace;
                    title.y = head.y + (head.height - title.height) / 2;
                    break;
                case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = true;
                    head.x = this.baseLine.x + (this.baseLineWidth - head.width) / 2;
                    head.y = top;
                    title.x = head.x + head.width + this.space + this.subjectAndLineSpace;
                    title.y = head.y + (head.height - title.height) / 2;
                    break;
                default:
                    head.isHidden = false;
                    title.isHidden = false;
                    desc.isHidden = false;
                    head.x = this.baseLine.x - head.width - this.subjectAndLineSpace;
                    head.y = top;
                    title.x = this.baseLine.x + this.baseLineWidth + this.subjectAndLineSpace;
                    if (head.y + (head.height - title.height) / 2 < top) {
                        title.y = top;
                    } else {
                        title.y = head.y + (head.height - title.height) / 2;
                    }
            }
            if (!desc.isHidden) {
                desc.x = title.x + this.getDesAndTitleHSpace(timeNode);
                desc.y = title.y + this.BaseLayout.getNodeHeight(title) + this.getDesAndTitleVSpace(timeNode);

                top = desc.y + this.BaseLayout.getNodeHeight(desc) + this.subjectSpace;
            } else if (!title.isHidden) {
                top = title.y + this.BaseLayout.getNodeHeight(title) + this.subjectSpace;
            }
            if (!head.isHidden && top < (head.y + this.BaseLayout.getNodeHeight(head) + this.subjectSpace)) {
                top = head.y + this.BaseLayout.getNodeHeight(head) + this.subjectSpace;
            }
        }

        this.baseLine.width = this.baseLineWidth;
        this.baseLine.height = top - this.subjectSpace + this.firstSubjectBaseLineTopSpace - this.baseLine.y;
        this.baseLine.timeLineContent.lineContentWidth = this.baseLineWidth;
        this.baseLine.timeLineContent.lineContentHeight = this.baseLine.height;
        let dots = new Array();

        let mainMindElementDataDictArray = this.mainMindElementDataDict.keys();
        let mainMindElementDataDictLength = mainMindElementDataDictArray.length

        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let key = mainMindElementDataDictArray[index];
            let timeNode = this.mainMindElementDataDict.get(key);

            let head = timeNode.head;
            let title = timeNode.title;
            let dotElementContent = new TimeDotElementContent();
            dotElementContent.targetId = timeNode.id;

            dotElementContent.x = this.baseLineWidth / 2;
            if (head.isHidden) {
                dotElementContent.y = title.y + title.height / 2 - this.baseLine.y;
            } else {
                dotElementContent.y = head.y + head.height / 2 - this.baseLine.y;
            }
            if (this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM ||
                this.timeNodeLayoutType == TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM) {
                dotElementContent.radius = head.height / 2;
            } else {
                dotElementContent.radius = this.radius;
            }
            if (this.settingData.useTimeLineColor) {
                dotElementContent.color = this.baseLine.timeLineContent.color;
            } else {
                dotElementContent.color = this.getHeadConnectLineColor(head, timeNode.title);
            }
            dots.push(dotElementContent);
        }
        this.baseLine.timeLineContent.dots = this.getOrderDot(dots);

    }

    getOrderNodes() {
        let list = new Array();
        let mainMindElementDataDictArray = this.mainMindElementDataDict.keys();
        let mainMindElementDataDictLength = mainMindElementDataDictArray.length

        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let key = mainMindElementDataDictArray[index];
            let node = this.mainMindElementDataDict.get(key);
            list.push(node);
        }
        list.sort(function (data1, data2) {
            if (data1.level == data2.level) {
                return 0;
            } else if (data1.level < data2.level) {
                return -1;
            } else {
                return 1;
            }
        })
        return list;

    }

    getOrderDot(dots) {     //设置排序，用于划线
        let list = new Array();
        dots.forEach(node => {
            list.push(node);
        });

        list.sort(function (data1, data2) {
            if (data1.y == data2.y) {
                return 0;
            } else if (data1.y < data2.y) {
                return -1;
            } else {
                return 1;
            }
        })
        return list;

    }

    setElementSytle() {
    }

    getHeadConnectLineColor(head, title) {
        let color = this.baseLine.timeLineContent.color;
        if (!head.isHidden && !Colors.isClear(head.borderColor) && head.borderWidth > 0) {
            color = head.borderColor;
        } else if (!head.isHidden && !Colors.isClear(head.backgroundColor)) {
            color = head.backgroundColor;
        } else if (!head.isHidden && head.textContent != null &&
            !Colors.isClear(head.textContent.textColor)) {
            color = head.textContent.textColor;
        } else if (!Colors.isClear(title.borderColor) && title.borderWidth > 0) {
            color = title.borderColor;
        } else if (!Colors.isClear(title.backgroundColor)) {
            color = title.backgroundColor;
        } else if (title.textContent != null &&
            !Colors.isClear(title.textContent.textColor)) {
            color = title.textContent.textColor;
        }
        return color;
    }

    getRadius() {  //获取线条上的小圆圈的半径。
        let radius = Math.min(this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth) * 2, this.UiUtil.dip2px(4));
        if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_CONNECT_HEAD_ORDER ||
            this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER) {
            radius = this.UiUtil.dip2px(8) + this.UiUtil.dip2px(this.baseLine.timeLineContent.lineWidth);
            radius = Math.min(radius, this.UiUtil.dip2px(10));
        } else if (this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_CIRCULAR_ORDER_1 ||
            this.baseLine.timeLineContent.nodeType == TimeLineNodeType.TIME_LINE_HEXAGON_ORDER) {
            radius = this.UiUtil.dip2px(15);
        }
        return radius;
    }


    getHeadMaxSize() {  //获取所有节点的头部的宽度最大值。
        let width = 0;
        let mainMindElementDataDictArray = this.mainMindElementDataDict.keys();
        let mainMindElementDataDictLength = mainMindElementDataDictArray.length

        for (let index = 0; index < mainMindElementDataDictLength; index++) {
            let key = mainMindElementDataDictArray[index];
            let node = this.mainMindElementDataDict.get(key);
            if (node.head.width > width) {
                width = node.head.width;
            }
        }
        return width;
    }

    setHeadNodeWidth(data, newAddWidth) {
        if (data.isEmpty()) {
            return;
        }
        data.width = data.width + newAddWidth;
        if (data.imageContent != null) {
            data.imageContent.x = data.imageContent.x + newAddWidth / 2;
        }
        if (data.textContent != null) {
            data.textContent.x = data.textContent.x + newAddWidth / 2;
        }
        if (data.generalizationContent != null) {
            data.generalizationContent.x = data.generalizationContent.x + newAddWidth / 2;
        }
        if (data.iconElementContents != null) {
            let iconCount = data.iconElementContents.length;
            if (iconCount > 0) {
                for (let index = 0; index < iconCount; index++) {
                    data.iconElementContents[index].x = data.iconElementContents[index].x + newAddWidth / 2;
                }
            }
        }
        if (data.linkElementContent != null) {
            data.linkElementContent.x = data.linkElementContent.x + newAddWidth / 2;
        }
        if (data.remarksElementContent != null) {
            data.remarksElementContent.x = data.remarksElementContent.x + newAddWidth / 2;
        }
    }

    setTimeLinePoint() {
        let contentX = this.title.x + this.title.width / 2;
        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
                this.baseLine.x = contentX - this.baseLineWidth / 2;
                break;
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
            case TimeNodeLayoutType.TITLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                this.baseLine.x = this.title.x;
                break;
            default:
                this.baseLine.x = contentX - this.baseLineWidth / 2;
        }

        this.baseLine.y = this.title.y + this.BaseLayout.getNodeHeight(this.title) + this.baseLineTitleSpace;
    }

    getTimeMindTypeNodeWidthByHorizontal(node) {
        let result = 0;

        switch (this.timeNodeLayoutType) {
            case TimeNodeLayoutType.NORMAL:
            case TimeNodeLayoutType.HEAD_TITLE_DES_MIDDLE_BOTTOM:
                result = node.head.width;
                result = Math.max(result, node.title.width);
                result = Math.max(result, node.desc.width);
                break;
            case TimeNodeLayoutType.TITLE_DES_BOTTOM:
                result = Math.max(result, node.title.width);
                result = Math.max(result, node.desc.width);
                break;
            case TimeNodeLayoutType.TITLE_BOTTOM:
                result = Math.max(result, node.title.width);
                break;
            case TimeNodeLayoutType.HEAD_TITLE_TOP_BOTTOM:
            case TimeNodeLayoutType.HEAD_TITLE_MIDDLE_BOTTOM:
                result = node.head.width;
                result = Math.max(result, node.title.width);
                break;
            default:
        }

        return result;
    }

    addSubjectSpcaeVertical( value) {
        return value + this.settingData.subjectSpcaeVertical;
    }

    addSonSubjectSpcaeVertical( value) {
        return value + this.settingData.sonSubjectSpcaeVertical;
    }

    addSubjectSpcaeHorizontal( value) {
        return value + this.settingData.subjectSpcaeHorizontal;
    }

    addSonSubjectSpcaeHorizontal( value) {
        return value + this.settingData.sonSubjectSpcaeHorizontal;
    }

    isBackgroundAndBorder(data) {
        return (data.borderWidth > 0 && !Colors.isClear(data.borderColor)) || !Colors.isClear(data.backgroundColor);
    }

    getDesAndTitleVSpace(node) {
        let head = node.head;
        let title = node.title;
        let desc = node.desc;
        let titleEdgeInsets = this.getTextEdgeInsets(title);
        let descEdgeInsets = this.getTextEdgeInsets(desc);
        if (!this.isBackgroundAndBorder(title) && !this.isBackgroundAndBorder(desc)) {
            return -(titleEdgeInsets.bottom + descEdgeInsets.top) + this.sonSubjectAndLineSpace;
        } else if (this.isBackgroundAndBorder(title) && !this.isBackgroundAndBorder(desc)) {
            return this.sonSubjectAndLineSpace;
        } else if (!this.isBackgroundAndBorder(title) && this.isBackgroundAndBorder(desc)) {
            return this.sonSubjectAndLineSpace;
        } else {
            return this.titleDescMaxSpace + this.sonSubjectAndLineSpace;
        }
    }

    getDesAndTitleHSpace(node) {
        let title = node.title;
        let desc = node.desc;
        let titleEdgeInsets = this.getTextEdgeInsets(title);
        let descEdgeInsets = this.getTextEdgeInsets(desc);

        if (this.isBackgroundAndBorder(title) && this.isBackgroundAndBorder(desc)) {
            return 0;
        }  else if (this.isBackgroundAndBorder(title) && !this.isBackgroundAndBorder(desc)) {
            return -(descEdgeInsets.left * 2);
        } else if (!this.isBackgroundAndBorder(title) && this.isBackgroundAndBorder(desc)) {
            return titleEdgeInsets.left * 2;
        } else {
            if (title.textContent != null && desc.textContent != null) {
                return title.getPadingLeft() + titleEdgeInsets.left - desc.getPadingLeft() - descEdgeInsets.left
            } else {
                return (titleEdgeInsets.left - descEdgeInsets.left) * 2;
            }
        }
    }

    getDataBackgroundAndBorderPadding(data) {
        if (this.isBackgroundAndBorder(data)) {
            return 0;
        }
        return this.getTextEdgeInsets(data).left * 2;
    }

    getTextEdgeInsets(data) {
        switch (data.type) {
            case MindElementType.MAIN_SUBJECT:
                return Config.MainInputUIEdgeInsets;
            case MindElementType.SUBJECT:
                return Config.SubjectInputUIEdgeInsets;
            case MindElementType.CONTENT_GENERALIZATION:
                return Config.GeneralizationInputUIEdgeInsets;
            case MindElementType.SON_SUBJECT:
                return Config.SonSubjectInputUIEdgeInsets;
            case MindElementType.EXPLAIN:
                return Config.ExplainInputUIEdgeInsets;
            default:
                return Config.SubjectInputUIEdgeInsets;
        }
    }
}
export default TimeLineLayout