import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";
import IconLayoutType from "../../../../viewmodel/datatype/IconLayoutType";
import TextAndIconAndConnectMap from "./TextAndIconAndConnectMap";


/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */
class TextAndIconAndConnectMapAndTask extends TextAndIconAndConnectMap {
    constructor(data, nodeLayoutType) {
        super(data, nodeLayoutType)
    }

    calculate() {
        if (!this.data.isContainConnectMapContent()) {
            return;
        }
        let textMindElementContent = this.getTextMindElementContent();
        if (textMindElementContent == null) {
            return;
        }

        let connectMapElementContent = this.data.connectMapElementContent;
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;

        let size = this.includeIconAndTextConnectMapAndTaskSize();
        let iconAndTextConnectMapSize = this.includeIconAndTextConnectMapSize();
        let layoutType = this.getIconLayout();
        let iconSsize = this.caluleIconElementSize();

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconElementContents = this.data.iconElementContents;
        if (iconElementContents == null || iconElementContents.length == 0) {
            textMindElementContent.x = marginX;
            textMindElementContent.y = marginY;
            return;
        }
        let textConnectMapSize = this.includeConnectMapAndTextSize();
        let iconLeft = 0;
        let iconTop = 0;
        let connectMapTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == IconLayoutType.LAYOUT_LEFT) {
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconMargin) : 0;
            iconTop = (iconAndTextConnectMapSize.getHeight() - iconSsize.getHeight()) / 2;
            textMindElementContent.x = (iconSsize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((iconAndTextConnectMapSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + connectMapTextSpace;
            connectMapElementContent.y = ((iconAndTextConnectMapSize.getHeight()) - connectMapElementContent.height) / 2 + marginY;

        } else if (layoutType == IconLayoutType.LAYOUT_RIGHT) {
            iconLeft = (iconAndTextConnectMapSize.getWidth() - iconSsize.getWidth() - this.iconMargin);
            iconTop = (iconAndTextConnectMapSize.getHeight() - iconSsize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((iconAndTextConnectMapSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + connectMapTextSpace;
            connectMapElementContent.y = ((iconAndTextConnectMapSize.getHeight()) - connectMapElementContent.height) / 2 + marginY;

        } else if (layoutType == IconLayoutType.LAYOUT_TOP) {
            iconLeft = (iconAndTextConnectMapSize.getWidth() - iconSsize.getWidth()) / 2;
            iconTop = (this.iconSpace);
            textMindElementContent.x = ((iconAndTextConnectMapSize.getWidth()) - (textConnectMapSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconSsize.getHeight()) + marginY;

            connectMapElementContent.x = textMindElementContent.x + textMindElementContent.width + connectMapTextSpace;
            connectMapElementContent.y = textMindElementContent.y + ((textConnectMapSize.getHeight()) - connectMapElementContent.height) / 2;

        }
        for (let index = 0; index < iconElementContents.length; index++) {
            let iconCell = iconElementContents[index];
            if (index == 0) {
                iconCell.x = iconLeft + marginX;
            } else {
                iconCell.x = iconElementContents[index - 1].x + iconElementContents[index - 1].width + (this.iconSpace);
            }
            iconCell.y = iconTop + marginY;
        }

        let textEdgeInsets = this.getTextEdgeInsets();
        this.data.taskContent.x = marginX + textEdgeInsets.left;
        this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}
export default TextAndIconAndConnectMapAndTask