// package mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent;

// import mind.yushu.com.mindmap.utils.Colors;
import Colors from "../../../utils/Colors"


/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class TimeDotElementContent {
    constructor(){
        this.targetId = -1;
        this.x = -1;
        this.y = -1;
        this.radius = 4;
        this.left = 10;
        this.right = 10;
        this.lineClockwise = true;
        this.isSelected = false;
        this.color = Colors.clear;
    }

    set(obj) {
        this.x = obj.x;
        this.y = obj.y;
        this.radius = obj.radius;
        this.lineClockwise = obj.lineClockwise;
        this.right = obj.right;
        this.left = obj.left;
        return this;
    }

    // public  TimeDotElementContent() {
    // }

    copy() {
        let data = new TimeDotElementContent();
        data.targetId = this.targetId;
        data.x = this.x;
        data.y = this.y;
        data.radius = this.radius;
        data.isSelected = this.isSelected;
        data.color = this.color;
        return data;
    }

    equal(data, ignorePoint = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        result = result || data.x != this.x;
        result = result || data.y != this.y;
        result = result || data.targetId != this.targetId;
        result = result || data.radius != this.radius;
        result = result || data.isSelected != this.isSelected;
        result = result || data.color != this.color;
        return !result;
    }
    
}
export default TimeDotElementContent
