// package mind.yushu.com.mindmap.core.calcule.elementCalculation.widget;

import UiUtil from "../../../../../utils/UiUtil";
import Util from "../../../../../utils/Util";
import CGPoint from "../../../../../viewmodel/core/base/basedata/CGPoint";
import CGRect from "../../../../../viewmodel/core/base/basedata/Rect";
import MindPoint from "../../../../../viewmodel/mindelementdata/mindcontent/MindPoint";
import BaseMindElementDataCalculation from "../BaseMindElementDataCalculation";
import MindElementCalculation from "../MindElementCalculation";

// import android.util.Size;

// import java.util.ArrayList;
// import java.util.List;

// import mind.yushu.com.mindmap.core.calcule.elementCalculation.BaseMindElementDataCalculation;
// import mind.yushu.com.mindmap.core.calcule.elementCalculation.MindElementCalculation;
// import mind.yushu.com.mindmap.utils.UiUtil;
// import mind.yushu.com.mindmap.utils.Util;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGPoint;
// import mind.yushu.com.mindmap.viewmodel.core.base.basedata.CGRect;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;     //没有new
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.MindPoint;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.mindcontent.StatisticsCellData;  //没有new

// /**
//  * ProjectName: MindMap
//  * Created by tony on 1/12/21
//  * Copyright(c) 2020 mindyushu.com
//  */

class ColumnarCalculation extends BaseMindElementDataCalculation {
    constructor(data) {
        super(data)
        
        this.contextSpace = new UiUtil().dip2px(10);
        this.explainLineContextSpace = new UiUtil().dip2px(10);
        this.explainTextSpace = new UiUtil().dip2px(40);
        this.explainTextSpaceV = new UiUtil().dip2px(8);
        this.titleTop = new UiUtil().dip2px(10);
        this.explainLine = new UiUtil().dip2px(80);
    }


//     public ColumnarCalculation(MindElementData data) {
//         super(data);
//     }

    calculate() {
        if (!this.data.isStatisticsContent()) {
            return;
        }
        let count = this.data.statisticsContent.cellsData.length;
        let maxHeight = this.data.statisticsContent.radius * 2;
        let width = (maxHeight / 0.618);
        let maxValue = this.getMaxValue();
        let unitPx = (maxHeight / maxValue);
        let cellWidth = width / (count * 2 + 1);
        let startX = cellWidth;
        let rect = new CGRect(0, 0, width, maxHeight);
        let bottom = maxHeight;
        for (let index = 0; index < count; index++) {
            let cell = this.data.statisticsContent.cellsData[index];
            cell.width = cellWidth;
            cell.height = (unitPx * cell.value);
            cell.x = startX;
            cell.y = maxHeight - cell.height;
            startX += cellWidth * 2;

            let explainTitleSize = MindElementCalculation.caluleText(cell.explainTitle, cell.explainTitleFontSize, false);
            let explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);
            cell.explainTitleX = cell.x + (cell.width - explainTitleSize.getWidth())/2;
            cell.explainX = cell.explainTitleX + (explainTitleSize.getWidth() - explainSize.getWidth()) / 2;
            cell.explainTitleY = cell.y + cell.height + this.titleTop;
            cell.explainY = cell.explainTitleY + explainTitleSize.getHeight() + this.explainTextSpaceV;
            bottom = Math.max(bottom, cell.explainY + explainSize.getHeight());
        }

//        rect.x = Math.min(rect.x, this.data.statisticsContent.titleX);
//        rect.y = Math.min(rect.y, this.data.statisticsContent.titleY);
//        right = Math.max(right , this.data.statisticsContent.titleX);

        let moveX = this.contextSpace;
        let moveY = (maxHeight * 0.2) + this.contextSpace;

        for (let index = 0; index < count; index++) {
            let cell = this.data.statisticsContent.cellsData[index];
            cell.x = cell.x + moveX;
            cell.y = cell.y + moveY;
            let linesCount = cell.explainLines.length;
            for (let l = 0; l < linesCount; l++) {
                cell.explainLines[l].x = cell.explainLines[l].x + moveX;
                cell.explainLines[l].y = cell.explainLines[l].y + moveY;
            }
            cell.explainX = cell.explainX + moveX;
            cell.explainY = cell.explainY + moveY;
            cell.explainTitleX = cell.explainTitleX + moveX;
            cell.explainTitleY = cell.explainTitleY + moveY;
            let explainSize = MindElementCalculation.caluleText(cell.explain, cell.explainFontSize, false);
            bottom = Math.max(bottom, cell.explainY + explainSize.getHeight());
        }
        this.data.statisticsContent.titleX = this.data.statisticsContent.titleX + moveX;
        this.data.statisticsContent.titleY = this.data.statisticsContent.titleY + moveY;

        this.data.width = width + this.contextSpace * 2;
        this.data.height = bottom - rect.y + this.contextSpace;
    }

    getMaxValue() {
        let value = 0;
        let count = this.data.statisticsContent.cellsData.length;
        for (let index = 0; index < count; index++) {
            let cell = this.data.statisticsContent.cellsData[index];
            if (value < cell.value) {
                value = cell.value;
            }
        }
        if (value <= 0) {
            value = 1
        }
        return value;
    }
}

export default ColumnarCalculation