
import BaseMindElementDataCalculation from "./BaseMindElementDataCalculation"
import UiUtil from "../../../../utils/UiUtil";
import ImageLayoutType from "../../../../viewmodel/datatype/ImageLayoutType";
import MindElementType from "../../../../viewmodel/datatype/MindElementType";
import Size from "../../../../viewmodel/core/base/Size";

/**
 * ProjectName: MindMap
 * Created by tony on 2020/7/14
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndImageAndRemarksAndConnectMapAndLatex extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (this.data.imageContent == null || !this.data.isContainRemarksContent() || !this.data.isContainConnectMapContent()) {
            return;
        }
        let imageContent = this.data.imageContent;
        let textMindElementContent = this.getTextMindElementContent();
        let remarksElementContent = this.data.remarksElementContent;
        let connectMapElementContent = this.data.connectMapElementContent;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        let textRext = this.caluleTextCGRect();
        let textHeight = (textRext.height());
        remarksElementContent.width = textHeight;
        remarksElementContent.height = textHeight;
        connectMapElementContent.width = textHeight;
        connectMapElementContent.height = textHeight;

        let textRemarksConnectMapSize = this.includeTextAndRemarksAndConnectMapSize();
        let imageAndTextAndRemarksAndConnectMapSize = this.includeImageAndTextAndRemarksAndConnectMapSize();
        let size = this.addLatexSize(imageAndTextAndRemarksAndConnectMapSize);
        let layoutType = imageContent.layoutType;
        let iconsSize = new Size((imageContent.width), (imageContent.height));

        let marginPoint = this.getMargin(size);
        let marginX = marginPoint.left;
        let marginY = marginPoint.top;

        let iconLeft = 0;
        let iconTop = 0;
        let remarksTextSpace = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconTextSpace) : 0;
        if (layoutType == ImageLayoutType.LAYOUT_LEFT) {
            iconTop = (imageAndTextAndRemarksAndConnectMapSize.getHeight() - iconsSize.getHeight()) / 2;
            iconLeft = this.data.type == MindElementType.MAIN_SUBJECT || this.data.type == MindElementType.SUBJECT || this.data.type == MindElementType.CONTENT_GENERALIZATION ? (this.iconMargin) : 0;
            iconLeft = iconTop > iconLeft ? iconLeft : iconTop;
            textMindElementContent.x = (iconsSize.getWidth() + this.iconTextSpace + this.iconMargin) + marginX;
            textMindElementContent.y = ((imageAndTextAndRemarksAndConnectMapSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            remarksElementContent.x = textMindElementContent.x + textMindElementContent.width + remarksTextSpace;
            remarksElementContent.y = ((imageAndTextAndRemarksAndConnectMapSize.getHeight()) - remarksElementContent.height) / 2;
            connectMapElementContent.x = remarksElementContent.x + remarksElementContent.width + (this.iconSpace);
            connectMapElementContent.y = remarksElementContent.y;

        } else if (layoutType == ImageLayoutType.LAYOUT_RIGHT) {
            iconLeft = (size.getWidth() - iconsSize.getWidth() - this.iconMargin);
            iconTop = (imageAndTextAndRemarksAndConnectMapSize.getHeight() - iconsSize.getHeight()) / 2;
            textMindElementContent.x = marginX;
            textMindElementContent.y = ((imageAndTextAndRemarksAndConnectMapSize.getHeight()) - textMindElementContent.height) / 2 + marginY;

            remarksElementContent.x = textMindElementContent.x + textMindElementContent.width + remarksTextSpace;
            remarksElementContent.y = ((imageAndTextAndRemarksAndConnectMapSize.getHeight()) - remarksElementContent.height) / 2;
            connectMapElementContent.x = remarksElementContent.x + remarksElementContent.width + (this.iconSpace);
            connectMapElementContent.y = remarksElementContent.y;

        } else if (layoutType == ImageLayoutType.LAYOUT_TOP) {
            iconLeft = (size.getWidth() - iconsSize.getWidth()) / 2;
            iconTop = iconLeft > (this.iconSpace) ? (this.iconSpace) : iconLeft;
            textMindElementContent.x = ((size.getWidth()) - (textRemarksConnectMapSize.getWidth())) / 2 + marginX;
            textMindElementContent.y = iconTop + (iconsSize.getHeight()) + marginY;

            remarksElementContent.x = textMindElementContent.x + textMindElementContent.height + remarksTextSpace;
            remarksElementContent.y = textMindElementContent.y + ((textRemarksConnectMapSize.getHeight()) - remarksElementContent.height) / 2;
            connectMapElementContent.x = remarksElementContent.x + remarksElementContent.width + (this.iconSpace);
            connectMapElementContent.y = remarksElementContent.y;
        }
        imageContent.x = iconLeft;
        imageContent.y = iconTop;

        let textEdgeInsets = this.getTextEdgeInsets();
        this.data.latexContent.x = marginX + textEdgeInsets.left;
        this.data.latexContent.y = marginY + imageAndTextAndRemarksAndConnectMapSize.getHeight();
        this.data.latexContent.width = this.latexOriginalSize.getWidth();
        this.data.latexContent.height = this.latexOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);
    }
}


export default TextAndImageAndRemarksAndConnectMapAndLatex