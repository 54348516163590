// package mind.yushu.com.mindmap.viewmodel.core.base;

import Colors from "../.././../utils/Colors"
/**
 * Crated by tony on 2019/12/27
 */

class LineColorMode {
    constructor(


    ) {
        this.rootViewFillColor = 0x24405C;
        this.rootGeneralizationViewFillColor = 0x24405C;
        this.rootTextColor = Colors.white;
        this.rootTextFontSize = 20;
        this.rootBorderColor = 0x24405C;
        this.rootBorderWidth = 2;

        this.subjectViewFillColor = 0xFFD7A7;
        this.subjectTextColor = Colors.white80;
        this.subjectTextFontSize = 18;
        this.subjectBorderWidth = 2;

        this.sonSubjectViewFillColor = Colors.transparent;
        this.sonSubjectViewBorderColor = Colors.transparent;
        this.sonSubjectViewBorderWidth = 2;
        this.sonSubjectDarkTextColor = Colors.white;
        this.sonSubjectTextColor = Colors.black80;
        this.sonSubjectTextFontSize = 14;

        this.borderColor = 0xA6A6A6;
        this.lineWidth = 2;
        this.selectedColor = 0xFF4A54;
        // this.freeNodeTextColor = Colors.black80;
        // this.freeNodeFillColor = Colors.white;
        // this.freeNodeBorderColor = Colors.white;

        this.timeLineColor = 0xFFAF4A;
        // this.timeTitleTextColor = Colors.black80;
        this.timeTitleTextFontSize = 26;

        this.timeDotElementRadius = 4;
        this.lineColors = [
            0xEF3C2F, 0x70BDF2, 0x6DC210, 0x1CB9A7,
            0x2265BA, 0x5C31EA, 0xAD31EA, 0xEA25B9,
            0xEA2570, 0xEA253B];
    
        this.viewFillColorColors = [
            0xFFE5E2, 0xDFF1FE, 0xD7DDD1, 0xCED6D5,
            0xC9D4E1, 0xE5E0F6, 0xE5DCEA, 0xEFDFEB,
            0xFCE2EC, 0xEADADC];
    
        this.mindTimeTypeFillColor = 0xFFE5D6;
    }



    getSonSubjectViewFillColor() {
        return this.sonSubjectViewFillColor;
    }

    getSonSubjectViewBorderWidth() {
        return this.sonSubjectViewBorderWidth;
    }

    getSonSubjectViewBorderColor() {
        return this.sonSubjectViewBorderColor;
    }
}

export default LineColorMode