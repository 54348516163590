const isClickSaveMapButton = {
    value: false
}; //是否点击保存导图按钮
const isOffline = {
    value: false
}; //是否离线过
const iconChangedColor = {
    red: 16533335,
    yellow: 16560696,
    green: 1744004,
    blue: 37119,
}; //判断进度图标改变的十进制颜色
const iconChangedHexColor = {
    [iconChangedColor.red]: "#ff0000",
    [iconChangedColor.yellow]: "#f76f20",
    [iconChangedColor.green]: "#1a9c84",
    [iconChangedColor.blue]: "#2992c9",
}//判断进度图标改变的十六进制颜色
const materialLibraryType = {
    InsertBgImage: 'insertBgImage',//插入背景图
    InsertImg: 'img',//以普通图片添加
    Nbg: 'nbg',//节点背景
    Bbg: 'bbg',//包裹线背景
}//素材库类型
const saveImgInsertBgType = {
    Stretching: 'stretching',//拉伸
    EqualRatio: 'equalRatio'//等比
}//保存图片插入背景图类型
const eventBusMethod = {
    InsertBgImgEvent: 'insertBgImageEvent',//保存图片插入背景图
    ScanCreationEvent: 'ocrScanCreationEvent',//打开ocr扫描创建
    PrintImg: 'printImg',//打印
}//eventBus事件
const sessionStorageKey = {
    ImgSaveMode: 'imgSaveMode',//保存图片类型
}//sessionStorage名字
const localStorageKey = {
    ColorMode: 'colorMode',//调色板主体色块颜色
}//localStorage名字
const ocrSupportedScanImgTypes = [
    '.png', '.jpg', '.webp', '.jpeg'
]//ocr支持扫描图片类型
const ocrScanExtractTypes = {
    ScanExtractionNode: 'Scan_Extraction_Node',//节点内容
    ScanExtractionRemarks: 'Scan_Extraction_Remarks',//备注内容
}//ocr扫描提取类型
const saveImgModes = {
    AllNodeSaveImg: 'allNodeSaveImg',//保存图片
    SelectNodeSaveImg: 'selectNodeSaveImg',//选择节点导出图片
    MultiSelectNodeSaveImg: 'multiSelectNodeSaveImg',//多选节点导出图片
    PrintPictures: 'printPictures',//打印图片
}//保存图片类型

export {
    isClickSaveMapButton,
    isOffline,
    iconChangedColor,
    iconChangedHexColor,
    materialLibraryType,
    saveImgInsertBgType,
    eventBusMethod,
    sessionStorageKey,
    localStorageKey,
    ocrSupportedScanImgTypes,
    ocrScanExtractTypes,
    saveImgModes
}
