
import getString from '../../../common/lang/language';
import strings from '../../../common/lang/strings';
import Strings from '../../../utils/Strings';
import Util from '../../../utils/Util';
import MindElementContent from './MindElementContent';

/**
 * Created by tony on 2019/12/18.
 */

 class TaskContent extends MindElementContent {
    constructor() {
        super();
        this.startTime = -1; //精确到秒 当前开始时间00点00分01秒
        this.endTime = -1; //精确到秒 当前结束时间23点59分59秒
        this.peoples = new Array();
        this.textFontSize = 11;
        this.priority = 1;//1较低🌟，2普通🌟🌟，3紧急🌟🌟🌟，4非常紧急🌟🌟🌟🌟
        this.taskProgress = 50; //0-100 任务完成度
        this.sonTaskProgress = new Array();//子任务完成度
    }

    getContentText() {
        let text = "";

        if (this.startTime > -1 && this.endTime > -1) {
            text = Util.formatTimeValueForTask(this.startTime);
            text += " : " + Util.formatTimeValueForTask(this.endTime) + " ";
        } else if (this.startTime > -1 && this.endTime == -1) {
            text = Util.formatTimeValueForTask(this.startTime);
            text += " : --" + " ";
        } else if (this.startTime == -1 && this.endTime > -1) {
            text = "--";
            text += " : " + Util.formatTimeValueForTask(this.endTime) + " ";
        }
        let peopleValue = "";
        for (var index = 0; index < this.peoples.length; index++) {
            let name = this.peoples[index].name;
            peopleValue += index > 0 ? "," + name : name;
        }
        if (new Strings().isEmpty(text) && new Strings().isEmpty(peopleValue)) {
            return "";
        }
        if (new Strings().isEmpty(peopleValue)) {
            return text;
        }
        if (new Strings().isEmpty(text)) {
            text += "-- :-- (" + peopleValue + ")";
        } else {
            text += "(" + peopleValue + ")";
        }
        return text;
    }

    getExplain() {
        let explain = "";
        let currentTime = Util.getCurrentTimeInsecond();
        if (this.startTime > currentTime && this.startTime > 0) {
            let time = Util.timeForma(this.startTime - currentTime);
            explain = time + " "+getString(strings.Mind_Start_Later);
        } else if (this.endTime < currentTime && this.endTime > 0) {
            let time = Util.timeForma(currentTime - this.endTime);
            explain = getString(strings.Mind_Over)+": " + time;
        } else if (this.startTime > 0 && this.endTime > 0 && this.startTime < currentTime && this.endTime > currentTime) {
            if (this.startTime == 0 && this.endTime > 0) {
                if (this.endTime > currentTime) {
                    let time = Util.timeForma(this.endTime - currentTime);
                    explain = getString(strings.Mind_In_Progress_Remaining)+": " + time;
                } else {
                    let time = Util.timeForma(currentTime - this.endTime);
                    explain = getString(strings.Mind_Over)+": " + time;
                }
            } else if (this.endTime == 0 && this.startTime > 0) {
                if (this.startTime > currentTime) {
                    let time = Util.timeForma(this.startTime - currentTime);
                    explain = time + " "+getString(strings.Mind_Start_Later);
                } else {
                    let time = Util.timeForma(currentTime - this.startTime);
                    explain = getString(strings.Mind_In_Progress_Time)+": " + time;
                }
            } else if (this.startTime > 0 && this.endTime > 0 && this.startTime < currentTime && this.endTime > currentTime) {
                let time = Util.timeForma(this.endTime - currentTime);
                explain = getString(strings.Mind_In_Progress_Remaining)+": " + time;
            }
        } else if (this.startTime <= 0 && this.endTime > 0) {
            if (this.endTime > currentTime) {
                let time = Util.timeForma(this.endTime - currentTime);
                explain = getString(strings.Mind_In_Progress_Remaining)+": " + time;
            } else {
                let time = Util.timeForma(currentTime - this.endTime);
                explain = getString(strings.Mind_Over)+": " + time;
            }
        }
        if (this.taskProgress < 0) {
            this.taskProgress = 0;
        }
        if (!new Strings().isEmpty(explain)) {
            explain += " | ";
        }
        if (this.taskProgress < 100) {
            explain += getString(strings.Mind_level)+": " + this.taskProgress + "%";
        } else {
            explain += getString(strings.Mind_Completed);
        }
        return explain;
    }

    copy() {
        let data = new TaskContent();
        data.x = this.x;
        data.y = this.y;
        data.width = this.width;
        data.height = this.height;

        data.startTime = this.startTime;
        data.endTime = this.endTime;
        data.textFontSize = this.textFontSize;
        data.taskProgress = this.taskProgress;
        data.priority = this.priority;

        var list = new Array();
        let count = this.sonTaskProgress.length;
        for (let index = 0; index < count; index++) {
            var cell = Math.floor(this.sonTaskProgress[index]);
            
            list.push(cell);
        }
        data.sonTaskProgress = list;

        var peopleList = new Array();
        let countPeople = this.peoples.length;
        for (let index = 0; index < countPeople; index++) {
            var cell = this.peoples[index]
            peopleList.push(cell.copy());
        }
        data.peoples = peopleList;

        return data;
    }

    equal(data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.startTime != this.startTime;
        result = result || data.endTime != this.endTime;
        result = result || data.textFontSize != this.textFontSize;
        result = result || data.taskProgress != this.taskProgress;
        result = result || data.priority != this.priority;
        result = result || data.sonTaskProgress.length != this.sonTaskProgress.length;
        if (!result) {
            let count = data.sonTaskProgress.length;
            for (let index = 0; index < count; index++) {
                result = result || data.sonTaskProgress[index]  != this.sonTaskProgress[index];
                if (result) {
                    break;
                }
            }
        }
        result = result || data.peoples.length != this.peoples.length;
        if (!result) {
            var count = data.peoples.length;
            for (var index = 0; index < count; index++) {
                result = result || !data.peoples[index].equal(this.peoples[index]);
                if (result) {
                    break;
                }
            }
        }
        return !result;
    }

    stickStyle(data) {
        if (data == null) {
            return;
        }
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
    }

    stickStyle( data) {
        if (data == null) {
            return;
        }
    }

    stickColorStyle( data) {
        if (data == null) {
            return;
        }
    }

}
export default TaskContent