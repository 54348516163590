// import mind.yushu.com.mindmap.viewmodel.mindelementdata.LineMindTypeNode;
// import mind.yushu.com.mindmap.viewmodel.mindelementdata.MindElementData;

import LineMindTypeNode from "../../../viewmodel/mindelementdata/LineMindTypeNode"
import MindElementData from "../../../viewmodel/mindelementdata/MindElementData"
import BaseLayout from '../../../viewmodel/core/layout/BaseLayout'
import HashMap from "../../../viewmodel/core/base/HashMap";
import Config from "./Config";
import IdGenerator from "../../../viewmodel/core/base/IdGenerator";
import UiUtil from "../../../utils/UiUtil";
import Colors from "../../../utils/Colors";
import CGRect from "../../../viewmodel/core/base/basedata/Rect";
/**
 * Created by tony on 2019/12/27
 */

class NodesRectCalculation {
    constructor(explainMinds = new HashMap()) {
        this.explainMindElementDataDict = explainMinds;
        this.dataList = new Array();
    }

    getNodes(node) {
        this.dataList = [];
        this.setNodeDataList(node);
        return this.dataList;
    }

    getNodesForHeight(node) {
        this.dataList = [];
        this.setNodeDataListForHeight(node, new LineMindTypeNode());
        return this.dataList;
    }

    calcule(node) {
        this.dataList = [];
        this.setNodeDataList(node);
        return this.calculeDatas(this.dataList);
    }

    setNodeDataListForHeight(node, parentNode) {
        if (node.value.isHidden) {
            return;
        }
        
        if (parentNode == null || parentNode.isEmpty() ||
            parentNode.children.length == 0) {
            this.dataList.push(node.value);
        } else {
            let nodeIndex = -1;
            for (let index = 0; index < parentNode.children.length; index++) {
                const child = parentNode.children[index];
                if (child.value.id == node.value.id) {
                    nodeIndex = index;
                    break;
                }
            }
            if (parentNode.children.length > 1 && nodeIndex > 0) {
                this.dataList.push(node.value);
            }
        }      
        node.children.forEach(child => {
            this.setNodeDataListForHeight(child, node);
        });
    }

    setNodeDataList(node) {
        if (node.value.isHidden) {
            return;
        }
        this.dataList.push(node.value);
        node.children.forEach(child => {
            this.setNodeDataList(child);
        });
    }

    calculeNodes(nodes) {

        var datas = new Array();
        nodes.forEach(node => {
            datas.push(node.value);
        });

        return this.calculeDatas(datas);
    }

    calculeDatas(datas) {
        if (datas.length == 0) {
            return new CGRect(0, 0, 0, 0);
        }

        var left = 1000000;
        var top = 1000000;
        var right = -1000000;
        var bottom = -1000000;
        datas.forEach(mind => {
            if (!mind.isHidden) {

                left = Math.min(left, mind.x);
                top =  Math.min(top, mind.y);
                right = Math.max(right, mind.x + mind.width)
                bottom = bottom < mind.y + new BaseLayout().getNodeHeight(mind) ? mind.y + new BaseLayout().getNodeHeight(mind) : bottom;

                let explainData = this.getNodeExplain(mind)
                if (!explainData.isEmpty()) {
                    let borderWidth = new UiUtil().dip2px(1);
                    if (mind.borderWidth > 0 && !Colors.isClear(mind.borderColor)) {
                        borderWidth += mind.borderWidth;
                    }
                    if (explainData.borderWidth > 0 && !Colors.isClear(explainData.borderColor)) {
                        borderWidth += explainData.borderWidth;
                    }
                    left = Math.min(left, mind.x + mind.width/2 - explainData.width/2);
                    top =  Math.min(top, mind.y - explainData.height - Config.NodeFloatExplainSpace - borderWidth);
                    right = Math.max(right, mind.x + mind.width / 2 + explainData.width/2)
                }
            }
            
        });
        return new CGRect(left, top, right -left , bottom - top);
    }

    getNodeExplain(data) { //获取节点data的解释
        if (data.id == IdGenerator.INVALID_ID || this.explainMindElementDataDict == null) {
            return new MindElementData().emptyMindNode();
        }
        let explainMindElementDatas = this.explainMindElementDataDict.values();
        let explainMindElementDatasCount = explainMindElementDatas.length;
        for (let index = 0; index < explainMindElementDatasCount; index++) {
            let item = explainMindElementDatas[index];
            if (item.parentNodeId == data.id) {
                return item;
            }
        }
        return new MindElementData().emptyMindNode();
    }
}

export default NodesRectCalculation
