// package mind.yushu.com.mindmap.viewmodel.core.base.basedata;

// import android.graphics.Point;
import Point from '../Point'
/**
 * ProjectName: MindMap
 * Created by tony on 2020/6/1
 * Copyright(c) 2020 mindyushu.com
 */

class CGPoint extends Point {
    constructor(x = 0, y = 0){
        super(x, y);
        this.x = x
        this.y = y
    }


    // public CGPoint(int x, int y) {
    //     super(x, y);
    //     this.x = x;
    //     this.y = y;
    // }

    // CGPoint(x, y) {
    //     super(x, y);
    //     this.x = x;
    //     this.y = y;
    // }

    // public CGPoint(double x, double y) {
    //     super((int)x, (int)y);
    //     this.x = (int)x;
    //     this.y = (int)y;
    // }

    // init() {
    //     return new CGPoint(0, 0);
    // }

    init(x, y) {
        return new CGPoint(x, y);
    }

    // public static CGPoint init(double x, double y) {
    //     return new CGPoint(x, y);
    // }

    // public static CGPoint init(float x, int y) {
    //     return new CGPoint((int)x, y);
    // }

    // public static CGPoint init(int x, float y) {
    //     return new CGPoint(x, (float)y);
    // }

    // public static CGPoint init(float x, float y) {
    //     return new CGPoint(x, y);
    // }
}
export default CGPoint