import MindElementContent from "./MindElementContent";
import Colors from "../../../utils/Colors"

class MoreContent extends MindElementContent {
    constructor(width, height,styleColor = Colors.white, tintColor = Colors.clear) {
        super(width, height);
        this.styleColor = styleColor;
        this.tintColor = tintColor;
    }
    
    copy() {
        let data = new MoreContent(this.width, this.height);
        data.x = this.x;
        data.y = this.y;
        data.styleColor = this.styleColor;
        data.tintColor = this.tintColor;
        return data;
    }

    equal( data, ignorePoint = false, ignoreSize = false) {
        let result = false;
        if (!ignorePoint) {
            result = result || data.x != this.x;
            result = result || data.y != this.y;
        }
        if (!ignoreSize) {
            result = result || data.width != this.width;
            result = result || data.height != this.height;
        }
        result = result || data.styleColor != this.styleColor;
        result = result || data.tintColor != this.tintColor;

        return !result;
    }
}

export default MoreContent