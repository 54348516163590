import Size from '../../../../viewmodel/core/base/Size'
import BaseMindElementDataCalculation from './BaseMindElementDataCalculation'
import MindElementAlignmentType from '../../../../viewmodel/datatype/MindElementAlignmentType'
/**
 * ProjectName: MindMap
 * Created by tony on 2020/5/27
 * Copyright(c) 2020 mindyushu.com
 */

class TextAndTaskCalculation extends BaseMindElementDataCalculation {
    constructor(data,nodeLayoutType) {
        super(data,nodeLayoutType);
    }

    calculate() {
        if (this.data.taskContent == null) {
            return;
        }

        let textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }
        if (textMindElementContent == null) {
            return;
        }
        let edgeInsets = this.getTextEdgeInsets();
        let size = this.getSize();
        let marginPoint = this.getMargin(size);
        switch (this.data.alignmentType) {
            case MindElementAlignmentType.RIGHT:
                textMindElementContent.x =  marginPoint.left + size.getWidth() - textMindElementContent.width;
                textMindElementContent.y = marginPoint.top;
                this.data.taskContent.x = marginPoint.left + size.getWidth() - this.taskOriginalSize.getWidth();
                this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
                break;
            case MindElementAlignmentType.MIDDLE:
                textMindElementContent.x =  marginPoint.left + (size.getWidth() - textMindElementContent.width)/2;
                textMindElementContent.y = marginPoint.top;
                this.data.taskContent.x = marginPoint.left + (size.getWidth() - this.taskOriginalSize.getWidth())/2;
                this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
                break;
            default: //case LEFT
                textMindElementContent.x = marginPoint.left;
                textMindElementContent.y = marginPoint.top;
                this.data.taskContent.x = marginPoint.left + edgeInsets.left;
                this.data.taskContent.y = textMindElementContent.y + textMindElementContent.height;
        }
        this.data.taskContent.width = this.taskOriginalSize.getWidth();
        this.data.taskContent.height = this.taskOriginalSize.getHeight();

        this.setMindElementSize(size.getWidth(), size.getHeight(), marginPoint);

    }

    getSize() {
        let textMindElementContent = null;
        if (this.data.textContent != null) {
            textMindElementContent = this.data.textContent;
        } else if (this.data.generalizationContent != null) {
            textMindElementContent = this.data.generalizationContent;
        }

        return this.addTaskSize(new Size(textMindElementContent.width, textMindElementContent.height));
    }
}

export default TextAndTaskCalculation
